export default [
  {
    path: '/custom/flow/list',
    name: 'customFlowList',
    component: () => import('@/views/flow/custom/index.vue'),
    meta: {
      pageTitle: '流程管理',
      breadcrumb: [
        {
          text: '流程管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom/flow/node',
    name: 'customFlowNode',
    component: () => import('@/views/flow/custom/node.vue'),
    meta: {
      pageTitle: '流程管理',
      breadcrumb: [
        {
          text: '流程管理',
          to: '/custom/flow/list',
        },
        {
          text: '流程管理配置',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/chat',
    name: 'templateChat',
    component: () => import('@/views/template/chat.vue'),
    meta: {
      pageTitle: '流程管理',
      breadcrumb: [
        {
          text: '流程管理',
          to: '/custom/flow/list',
        },
        {
          text: '沟通模板',
          active: true,
        },
      ],
    },
  },
]
