export default [
  {
    path: '/asset',
    name: 'asset',
    component: () => import('@/views/dataIntelligent/itAssest/AssestView.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: 'IT资产管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/assetDetail',
    name: 'assetDetail',
    component: () => import('@/views/dataIntelligent/itAssest/AssestDetail.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: 'IT资产管理',
          to: '/asset',
        },
        {
          text: '资产详情',
          active: true,
        },
      ],
    },
  },
  {
    path: '/assestSave',
    name: 'assestSave',
    component: () => import('@/views/dataIntelligent/itAssest/AssestSave.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: 'IT资产管理',
          to: '/asset',
        },
        {
          text: '资产编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/infoDict',
    name: 'infoDict',
    component: () => import('@/views/dataIntelligent/infoDict/infoDict.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '个人信息字典',
          active: true,
        },
      ],
    },
  },

  {
    path: '/infoAssest',
    name: 'infoAssest',
    component: () => import('@/views/dataIntelligent/infoAssest/infoAssest.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '个人信息资产',
          active: true,
        },
      ],
      query: {
        a: 1
      }
    },
  },
  {
    path: '/dataFoundSave',
    name: 'dataFoundSave',
    component: () => import('@/views/dataIntelligent/infoAssest/dataFound/DataFoundSave.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '个人信息资产',
          to: { path: '/infoAssest' },
        },
        {
          text: '新增资产发现',
          active: true,
        },
      ],
      query: {
        a: 1
      }
    },
  },
  {
    path: '/dataFoundDetail',
    name: 'dataFoundDetail',
    component: () => import('@/views/dataIntelligent/infoAssest/dataFound/DataFoundDetail'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '个人信息资产',
          to: { path: '/infoAssest' },
        },
        {
          text: '资产发现详情',
          active: true,
        },
      ],
      query: {
        a: 1
      }
    },
  },
  {
    path: '/dataActivity',
    name: 'dataActivity',
    component: () => import('@/views/dataIntelligent/dataActivity/DataActivity.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '个人信息映射',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dataActivityFill',
    name: 'dataActivityFill',
    component: () => import('@/views/dataIntelligent/dataActivity/DataActivityFill.vue'),
    meta: {
      pageTitle: '数据映射',
      breadcrumb: [
        {
          text: '个人信息映射',
          to: { path: '/dataActivity' },
        },
        {
          text: '采集填写',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dataListSave',
    name: 'dataListSave',
    component: () => import('@/views/dataIntelligent/infoAssest/dataList/DataListSave.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '个人信息资产',
          to: '/infoAssest',
        },
        {
          text: '个人信息资产编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dataAtlas',
    name: 'dataAtlas',
    component: () => import('@/views/dataIntelligent/dataAtlas/DataAtlas.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据管理',
      breadcrumb: [
        {
          text: '个人信息图谱',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/dataIntelligent/business/Business.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '业务信息数据',
          active: true,
        },
      ],
    },
  },
  {
    path: '/businessFill',
    name: 'businessFill',
    component: () => import('@/views/dataIntelligent/business/BusinessFill.vue'),
    meta: {
      pageTitle: '数据智能',
      breadcrumb: [
        {
          text: '业务信息数据',
          active: true,
        },
      ],
    },
  },
]
