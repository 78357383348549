<template>
    <div></div>
</template>

<script>
  import { MenuNav } from '@/api/system/system'
  export default {
    props: {
       btnPerms: {
         type: Array
       },
      btnPermsStr: {
        type: Array
      }
    },
    created() {
      this.getBtnPerms()
    },
    methods: {
      treeToList(datas) {
        var arr = []
        formateData(datas, 0)

        function formateData(datas, level) {
          var level = level || 0
          level++
          for (var i in datas) {
            arr.push({
              id: datas[i]['id'],
              component: datas[i]['component'],
              type: datas[i]['type'],
              name: datas[i]['name'],
            })
            if (datas[i].childResources) {
              formateData(datas[i].childResources, level)
            }
          }
        }
        return arr
      },
      getBtnPerms() {
        MenuNav().then(res=>{
          const resData = res.data
          const listData = this.treeToList(resData.data)
          var buttonPerms = []
          listData.forEach(l=>{
            if (l.type === 5 && l.component) {
              buttonPerms.push(l.component)
            }
          })
          this.btnPermsStr.forEach((btn,index)=>{
            buttonPerms.forEach(b=>{
              if(btn === b){
                this.btnPerms[index] = true
              }
            })
          })
        })
      }
    }

  }
</script>

<style scoped>

</style>
