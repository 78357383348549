export default [
  {
    path: '/emergency',
    name: 'emergency',
    component: () => import('@/views/emergency/Emergency.vue'),
    meta: {
      pageTitle: '应急响应管理',
      breadcrumb: [
        {
          text: '应急响应管理',
          active: true,
        },
      ],
    },
  },
]
