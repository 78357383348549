export default [
  {
    path: '/cpm-channel',
    name: 'cpm-channel',
    component: () => import('@/views/cpm/CpmList.vue'),
    meta: {
      resource: 'cpm',
      pageTitle: '同意偏好管理',
      breadcrumb: [
        {
          text: '渠道管理',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cpm-statistic',
    name: 'cpm-statistic',
    component: () => import('@/views/cpm/CPM.vue'),
    meta: {
      resource: 'cpm',
      pageTitle: '同意偏好管理',
      breadcrumb: [
        {
          text: '统计分析',
          active: true,
        },
      ],
    },
  },

]
