import Vue from 'vue'
const countryTreeOptions = [
  {
    value: '非洲',
    label: '非洲',
    children: [
      { value: '阿尔及利亚', label: '阿尔及利亚',location: [3.08,36.42]},
      { value: '埃及', label: '埃及',location: [31.14,30.01]},
      { value: '安哥拉', label: '安哥拉',location: [13.15,-8.50] },
      { value: '利比亚', label: '利比亚',location: [13.07,32.49] },
      { value: '摩洛哥', label: '摩洛哥',location: [33.06,7.37] },
      { value: '苏丹', label: '苏丹',location: [32.35,15.31] },
      { value: '突尼斯', label: '突尼斯',location: [10.11,36.50] },
      { value: '贝宁', label: '贝宁',location: [2.42,6.23] },
      { value: '布基纳法索', label: '布基纳法索',location: [-1.30,12.15] },
      { value: '乍得', label: '乍得',location:  [14.59,12.10] },
      { value: '科特迪瓦', label: '科特迪瓦',location: [-5.17,6.49] },
      { value: '冈比亚', label: '冈比亚',location: [-16.40,13.28]},
      { value: '加纳', label: '加纳',location: [-0.06,5.35] },
      { value: '几内亚', label: '几内亚',location: [-13.49,9.29] },
      { value: '几内亚比绍', label: '几内亚比绍',location: [-15.45,11.45] },
      { value: '利比里亚', label: '利比里亚',location: [-10.47,6.18] },
      { value: '马里', label: '马里',location: [-7.55,12.34] },
      { value: '尼日尔', label: '尼日尔',location: [2.06,13.27] },
      { value: '毛里塔尼亚', label: '毛里塔尼亚',location: [57.30,-20.10] },
      { value: '尼日利亚', label: '尼日利亚',location: [7.32,9.05] },
      { value: '塞内加尔', label: '塞内加尔',location:  [-17.29,14.34] },
      { value: '塞拉利昂', label: '塞拉利昂',location: [-13.17,8.30] },
      { value: '多哥', label: '多哥',location: [1.20,6.09] },
      { value: '佛得角', label: '佛得角',location: [-23.34,15.02] },
      { value: '博茨瓦纳', label: '博茨瓦纳',location: [25.57,-24.45] },
      { value: '科摩罗', label: '科摩罗',location: [43.16,-11.40] },
      { value: '莱索托', label: '莱索托',location: [27.30,-29.18] },
      { value: '马达加斯加', label: '马达加斯加',location: [47.31,-18.55] },
      { value: '马拉维', label: '马拉维',location: [33.48,-14.00] },
      { value: '毛里求斯', label: '毛里求斯',location: [57.482012,-20.246695] },
      { value: '莫桑比克', label: '莫桑比克',location: [32.32,-25.58] },
      { value: '纳米比亚', label: '纳米比亚',location: [17.04,-22.35] },
      { value: '斯威士兰', label: '斯威士兰',location: [31.06,-26.18] },
      { value: '南非', label: '南非',location: [24.306405,-30.847533] },
      { value: '赞比亚', label: '赞比亚',location:  [28.16,-15.28] },
      { value: '津巴布韦', label: '津巴布韦',location: [31.02,-17.43] },
      { value: '布隆迪', label: '布隆迪',location: [29.18,-3.16] },
      { value: '吉布提', label: '吉布提',location: [42.20,11.08] },
      { value: '厄立特里亚', label: '厄立特里亚',location: [38.55,15.19] },
      { value: '埃塞俄比亚', label: '埃塞俄比亚',location: [38.42,9.02] },
      { value: '肯尼亚', label: '肯尼亚',location: [36.48,-1.17] },
      { value: '卢旺达', label: '卢旺达',location: [30.04,-1.59] },
      { value: '塞舌尔', label: '塞舌尔',location: [55.456332,-4.666017] },
      { value: '索马里', label: '索马里',location: [45.25,2.02] },
      { value: '坦桑尼亚', label: '坦桑尼亚',location: [35.45,-6.08] },
      { value: '乌干达', label: '乌干达',location: [32.30,0.20] },
    ],
  },
  {
    value: '美洲',
    label: '美洲',
    children: [
      {label:'加拿大', value: '加拿大',location: [-75.42,45.27] },
      {label:'墨西哥', value: '墨西哥',location: [-99.10,19.20]},
      {label:'美国', value: '美国',location:[-77.02,39.91]},
      {label:'伯利兹', value: '伯利兹',location:[-88.30,17.18]},
      {label:'哥斯达黎加', value: '哥斯达黎加',location:[-84.02,9.55]},
      {label:'萨尔瓦多', value: '萨尔瓦多',location: [-89.10,13.40]},
      {label:'危地马拉', value: '危地马拉',location:[-90.22,14.40]},
      {label:'洪都拉斯', value: '洪都拉斯',location:[-87.14,14.05]},
      {label:'尼加拉瓜', value: '尼加拉瓜',location: [-86.20,12.06]},
      {label:'巴拿马', value: '巴拿马',location:[-79.25,9.00]},
      {label:'安提瓜和巴布达', value: '安提瓜和巴布达',location:[-61.48,17.20]},
      {label:'巴哈马', value: '巴哈马',location:[-77.20,25.05]},
      {label:'巴巴多斯', value: '巴巴多斯',location: [-59.30,13.05]},
      {label:'古巴', value: '古巴',location: [-82.22,23.08]},
      {label:'多米尼克', value: '多米尼克',location:[-61.378558,15.506766]},
      {label:'多米尼加共和国', value: '多米尼加共和国',location: [-69.59,18.30]},
      {label:'格林纳达', value: '格林纳达',location: [-61.769262,12.010963]},
      {label:'海地', value: '海地',location:  [-72.20,18.40]},
      {label:'牙买加', value: '牙买加',location:  [-76.50,18.00]},
      {label:'圣基茨和尼维斯', value: '圣基茨和尼维斯',location:[-62.43,17.17]},
      {label:'圣卢西亚', value: '圣卢西亚',location: [-60.58,14.02]},
      {label:'圣文森及格瑞那丁', value: '圣文森及格瑞那丁',location: [-61.10,13.10]},
      {label:'圣文森特和格林纳丁斯', value: '圣文森特和格林纳丁斯',location: [-61.10,13.10]},
      {label:'特立尼达和多巴哥', value: '特立尼达和多巴哥',location:[-61.318881,10.484427]},
      {label:'阿根廷', value: '阿根廷',location:[-60.00,-36.30]},
      {label:'玻利维亚', value: '玻利维亚',location: [-68.10,-16.20]},
      {label:'巴西', value: '巴西',location:[-47.55,-15.47]},
      {label:'智利', value: '智利',location:[-70.40,-33.24]},
      {label:'哥伦比亚', value: '哥伦比亚',location:[-74.00,4.34]},
      {label:'厄瓜多尔', value: '厄瓜多尔',location:[-78.35,-0.15]},
      {label:'圭亚那', value: '圭亚那',location:[-52.18,5.05]},
      {label:'秘鲁', value: '秘鲁',location:[-77.00,-12.00]},
      {label:'巴拉圭', value: '巴拉圭',location:[-57.30,-25.10]},
      {label:'苏里南', value: '苏里南',location: [-55.10,5.50]},
      {label:'乌拉圭', value: '乌拉圭',location: [-56.11,-34.50]},
      {label:'委内瑞拉', value: '委内瑞拉',location:[-66.55,10.30]},
    ],
  },
  {
    value: '亚洲',
    label: '亚洲',
    children: [
      {
        label: '中国',
        value: '中国',
        children: [
          {label: '安徽',value:'安徽',children: []},
          {label: '澳门',value:'澳门'},
          {label: '北京',value:'北京'},
          {label: '福建',value:'福建',children: []},
          {label: '甘肃',value:'甘肃',children: []},
          {label: '广东',value:'广东',children: []},
          {label: '贵州',value:'贵州',children: []},
          {label: '海南',value:'海南',children: []},
          {label: '河北',value:'河北',children: []},
          {label: '河南',value:'河南',children: []},
          {label: '黑龙江',value:'黑龙江',children: []},
          {label: '湖北',value:'湖北',children: []},
          {label: '湖南',value:'湖南',children: []},
          {label: '吉林',value:'吉林',children: []},
          {label: '江苏',value:'江苏',children: []},
          {label: '江西',value:'江西',children: []},
          {label: '辽宁',value:'辽宁',children: []},
          {label: '内蒙',value:'内蒙',children: []},
          {label: '宁夏',value:'宁夏',children: []},
          {label: '青海',value:'青海',children: []},
          {label: '山东',value:'山东',children: []},
          {label: '山西',value:'山西',children: []},
          {label: '陕西',value:'陕西',children: []},
          {label: '上海',value:'上海'},
          {label: '四川',value:'四川',children: []},
          {label: '台湾',value:'台湾'},
          {label: '天津',value:'天津'},
          {label: '西藏自治区',value:'西藏自治区',children: []},
          {label: '西藏自治区',value:'西藏自治区',children: []},
          // {label: '香港',value:'香港',location: [115.12,21.23]},
          {label: '香港',value:'香港'},
          {label: '新疆自治区',value:'新疆自治区',children: []},
          {label: '云南',value:'云南',children: []},
          {label: '浙江',value:'浙江',children: []},
          {label: '重庆',value:'重庆'},
        ],
      },
      { label: '日本', value: '日本',location: [139.769486,35.729682] },
      { label: '蒙古', value: '蒙古' ,location:[103.169254,48.115817]},
      { label: '朝鲜', value: '朝鲜'  ,location:[125.30,39.09]},
      { label: '韩国', value: '韩国'  ,location: [126.58,37.31]},
      { label: '缅甸', value: '缅甸'  ,location:[96.20,16.45]},
      { label: '文莱', value: '文莱'  ,location: [115.00,4.52]},
      { label: '柬埔寨', value: '柬埔寨'  ,location: [104.55,11.33]},
      { label: '东帝汶', value: '东帝汶'  ,location: [125.34,-8.29]},
      { label: '印度尼西亚', value: '印度尼西亚' ,location: [106.49,-6.09] },
      { label: '马来西亚', value: '马来西亚' ,location: [101.41,3.09] },
      { label: '菲律宾', value: '菲律宾'  ,location: [121.03,14.40]},
      { label: '新加坡', value: '新加坡' ,location:[103.853756,1.366839] },
      { label: '泰国', value: '泰国'  ,location: [100.35,13.45]},
      { label: '越南', value: '越南' ,location: [105.55,21.05] },
      { label: '孟加拉国', value: '孟加拉国' ,location: [90.26,23.43] },
      { label: '不丹', value: '不丹' ,location: [89.45,27.31], },
      { label: '印度', value: '印度' ,location: [77.13,28.37] },
      { label: '马尔代夫', value: '马尔代夫' ,location: [73.28,4.00] },
      { label: '尼泊尔', value: '尼泊尔' ,location: [85.20,27.45] },
      { label: '巴基斯坦', value: '巴基斯坦' ,location: [73.10,33.40] },
      { label: '斯里兰卡', value: '斯里兰卡' ,location:[80.656864,7.927426] },
      { label: '哈萨克斯坦', value: '哈萨克斯坦' ,location:[71.30,51.10] },
      { label: '吉尔吉斯斯坦', value: '吉尔吉斯斯坦' ,location: [74.46,42.54] },
      { label: '塔吉克斯坦', value: '塔吉克斯坦' ,location: [68.48,38.33] },
      { label: '土库曼斯坦', value: '土库曼斯坦' ,location: [57.50,38.00] },
      { label: '乌兹别克斯坦', value: '乌兹别克斯坦' ,location: [69.10,41.20] },
      { label: '阿富汗', value: '阿富汗'  ,location: [69.11,34.28]},
      { label: '亚美尼亚', value: '亚美尼亚' ,location: [44.31,40.10] },
      { label: '阿塞拜疆', value: '阿塞拜疆' ,location: [49.56,40.29] },
      { label: '巴林', value: '巴林' ,location: [50.30,26.10] },
      { label: '塞浦路斯', value: '塞浦路斯' ,location: [33.25,35.10] },
      { label: '格鲁吉亚', value: '格鲁吉亚' ,location: [44.50,41.43] },
      { label: '伊朗', value: '伊朗' ,location: [51.30,35.44] },
      { label: '伊拉克', value: '伊拉克' ,location: [44.30,33.20] },
      { label: '以色列', value: '以色列' ,location: [35.12,31.47] },
      { label: '约旦', value: '约旦' ,location: [35.52,31.57] },
      { label: '科威特', value: '科威特' ,location: [48.00,29.30] },
      { label: '黎巴嫩', value: '黎巴嫩' ,location: [35.31,33.53] },
      { label: '阿曼', value: '阿曼' ,location: [58.36,23.37] },
      { label: '巴勒斯坦', value: '巴勒斯坦' ,location:[35.276618,31.954086] },
      { label: '卡塔尔', value: '卡塔尔' ,location: [51.35,25.15] },
      { label: '沙特阿拉伯', value: '沙特阿拉伯' ,location: [46.42,24.41] },
      { label: '叙利亚', value: '叙利亚' ,location: [36.18,33.30] },
      { label: '土耳其', value: '土耳其' ,location: [32.54,39.57] },
      { label: '阿拉伯联合酋长国', value: '阿拉伯联合酋长国' ,location:[53.739441,23.689135] },
      { label: '也门', value: '也门' ,location:[48.577904,15.556907] },
    ],
  },
  {
    value: '欧洲',
    label: '欧洲',
    children: [
      { label: '比利时', value: '比利时',location:[4.21,50.51] },
      { label: '法国', value: '法国',location: [2.20,48.50]},
      { label: '爱尔兰', value: '爱尔兰',location:[-6.15,53.21] },
      { label: '卢森堡', value: '卢森堡',location: [6.09,49.37], },
      { label: '摩纳哥', value: '摩纳哥',location: [7.411968,43.742795] },
      { label: '荷兰', value: '荷兰',location:[4.54,52.23]},
      { label: '英国', value: '英国',location:[-0.05,51.36] },
      { label: '挪威', value: '挪威',location:[10.45,59.55] },
      { label: '丹麦', value: '丹麦',location: [12.34,55.41] },
      { label: '芬兰', value: '芬兰',location:[25.03,60.15] },
      { label: '冰岛', value: '冰岛',location:[-21.57,64.10] },
      { label: '瑞典', value: '瑞典',location: [18.03,59.20] },
      { label: '奥地利', value: '奥地利',location: [16.22,48.12]},
      { label: '捷克', value: '捷克',location: [14.22,50.05] },
      { label: '德国', value: '德国',location:[13.25,52.30] },
      { label: '匈牙利', value: '匈牙利',location:  [19.05,47.29] },
      { label: '列支敦士登', value: '列支敦士登',location: [9.31,47.08] },
      { label: '波兰', value: '波兰',location:[21.00,52.13] },
      { label: '斯洛伐克', value: '斯洛伐克',location: [17.07,48.10]},
      { label: '瑞士', value: '瑞士',location:[7.28,46.57] },
      { label: '白俄罗斯', value: '白俄罗斯' ,location:[27.30,53.52]},
      { label: '爱沙尼亚', value: '爱沙尼亚',location: [24.48,59.22] },
      { label: '拉脱维亚', value: '拉脱维亚',location:[24.08,56.53] },
      { label: '立陶宛', value: '立陶宛',location: [25.19,54.38]},
      { label: '摩尔多瓦', value: '摩尔多瓦',location:  [28.50,47.02] },
      { label: '俄罗斯', value: '俄罗斯',location:  [37.35,55.45]},
      { label: '阿尔巴尼亚', value: '阿尔巴尼亚' ,location: [19.49,41.18]},
      { label: '安道尔', value: '安道尔',location:  [1.32,42.31]},
      { label: '波斯尼亚和黑塞哥维那', value: '波斯尼亚和黑塞哥维那',location: [18.26,43.52] },
      { label: '保加利亚', value: '保加利亚',location: [23.20,42.45] },
      { label: '克罗地亚', value: '克罗地亚' ,location: [15.58,45.50]},
      { label: '希腊', value: '希腊' ,location: [23.46,37.58]},
      { label: '意大利', value: '意大利' ,location:  [12.29,41.54]},
      { label: '马其顿', value: '马其顿',location:[21.26,42.01] },
      { label: '马耳他', value: '马耳他',location:  [14.31,35.54]},
      { label: '葡萄牙', value: '葡萄牙',location: [-9.10,38.42]},
      { label: '罗马尼亚', value: '罗马尼亚',location: [26.10,44.27] },
      { label: '圣马利诺', value: '圣马利诺',location: [12.43708,43.943399] },
      { label: '塞尔维亚', value: '塞尔维亚',location: [20.931695,44.019633]},
      { label: '黑山', value: '黑山',location:[19.508345,42.822842] },
      { label: '斯洛文尼亚', value: '斯洛文尼亚' ,location: [14.33,46.04]},
      { label: '西班牙', value: '西班牙',location:  [-3.45,40.25]},
      { label: '梵蒂冈', value: '梵蒂冈' ,location:[12.493058,41.908714]},
    ],
  },
  {
    value: '大洋洲',
    label: '大洋洲',
    children: [
      { label: '澳大利亚', value: '澳大利亚',location:[149.08,-35.15] },
      { label: '斐济', value: '斐济',location:[178.30,-18.06] },
      { label: '吉里巴斯', value: '吉里巴斯',location:[114.59096,-3.294948] },
      { label: '马绍尔群岛', value: '马绍尔群岛',location:[171.984227,6.050852] },
      { label: '密克罗尼西亚', value: '密克罗尼西亚',location: [158.09,6.55] },
      { label: '瑙鲁', value: '瑙鲁',location:[166.930928,-0.531171] },
      { label: '新西兰', value: '新西兰',location: [174.46,-41.19] },
      { label: '帕劳', value: '帕劳',location: [134.28,7.20] },
      { label: '巴布亚新几内亚', value: '巴布亚新几内亚',location },
      { label: '萨摩亚', value: '萨摩亚',location: [147.08,-9.24] },
      { label: '所罗门群岛', value: '所罗门群岛',location: [159.57,-9.27] },
      { label: '汤加', value: '汤加',location: [-174.00,-21.10] },
      { label: '图瓦卢', value: '图瓦卢' ,location: [179.13,-8.31]},
      { label: '瓦努阿图', value: '瓦努阿图' ,location: [168.18,-17.45]},
    ]
  }
]
const citys =  [
  {
    "province": "安徽",
    "city": "合肥",
    "longitude": "117.17",
    "latitude": "31.52"
  },
  {
    "province": "安徽",
    "city": "安庆",
    "longitude": "117.02",
    "latitude": "30.31"
  },
  {
    "province": "安徽",
    "city": "蚌埠",
    "longitude": "117.21",
    "latitude": "32.56"
  },
  {
    "province": "安徽",
    "city": "亳州",
    "longitude": "115.47",
    "latitude": "33.52"
  },
  {
    "province": "安徽",
    "city": "巢湖",
    "longitude": "117.52",
    "latitude": "31.36"
  },
  {
    "province": "安徽",
    "city": "滁州",
    "longitude": "118.18",
    "latitude": "32.18"
  },
  {
    "province": "安徽",
    "city": "阜阳",
    "longitude": "115.48",
    "latitude": "32.54"
  },
  {
    "province": "安徽",
    "city": "贵池",
    "longitude": "117.28",
    "latitude": "30.39"
  },
  {
    "province": "安徽",
    "city": "淮北",
    "longitude": "116.47",
    "latitude": "33.57"
  },
  {
    "province": "安徽",
    "city": "淮南",
    "longitude": "116.58",
    "latitude": "32.37"
  },
  {
    "province": "安徽",
    "city": "黄山",
    "longitude": "118.18",
    "latitude": "29.43"
  },
  {
    "province": "安徽",
    "city": "界首",
    "longitude": "115.21",
    "latitude": "33.15"
  },
  {
    "province": "安徽",
    "city": "六安",
    "longitude": "116.28",
    "latitude": "31.44"
  },
  {
    "province": "安徽",
    "city": "马鞍山",
    "longitude": "118.28",
    "latitude": "31.43"
  },
  {
    "province": "安徽",
    "city": "明光",
    "longitude": "117.58",
    "latitude": "32.47"
  },
  {
    "province": "安徽",
    "city": "宿州",
    "longitude": "116.58",
    "latitude": "33.38"
  },
  {
    "province": "安徽",
    "city": "天长",
    "longitude": "118.59",
    "latitude": "32.41"
  },
  {
    "province": "安徽",
    "city": "铜陵",
    "longitude": "117.48",
    "latitude": "30.56"
  },
  {
    "province": "安徽",
    "city": "芜湖",
    "longitude": "118.22",
    "latitude": "31.19"
  },
  {
    "province": "安徽",
    "city": "宣州",
    "longitude": "118.44",
    "latitude": "30.57"
  },
  {
    "province": "澳门",
    "city": "澳门",
    "longitude": "113.572861",
    "latitude": "22.142017"
  },
  {
    "province": "北京",
    "city": "北京",
    "longitude": "116.24",
    "latitude": "39.55"
  },
  {
    "province": "福建",
    "city": "福州",
    "longitude": "119.18",
    "latitude": "26.05"
  },
  {
    "province": "福建",
    "city": "长乐",
    "longitude": "119.31",
    "latitude": "25.58"
  },
  {
    "province": "福建",
    "city": "福安",
    "longitude": "119.39",
    "latitude": "27.06"
  },
  {
    "province": "福建",
    "city": "福清",
    "longitude": "119.23",
    "latitude": "25.42"
  },
  {
    "province": "福建",
    "city": "建瓯",
    "longitude": "118.20",
    "latitude": "27.03"
  },
  {
    "province": "福建",
    "city": "建阳",
    "longitude": "118.07",
    "latitude": "27.21"
  },
  {
    "province": "福建",
    "city": "晋江",
    "longitude": "118.35",
    "latitude": "24.49"
  },
  {
    "province": "福建",
    "city": "龙海",
    "longitude": "117.48",
    "latitude": "24.26"
  },
  {
    "province": "福建",
    "city": "龙岩",
    "longitude": "117.01",
    "latitude": "25.06"
  },
  {
    "province": "福建",
    "city": "南安",
    "longitude": "118.23",
    "latitude": "24.57"
  },
  {
    "province": "福建",
    "city": "南平",
    "longitude": "118.10",
    "latitude": "26.38"
  },
  {
    "province": "福建",
    "city": "宁德",
    "longitude": "119.31",
    "latitude": "26.39"
  },
  {
    "province": "福建",
    "city": "莆田",
    "longitude": "119.01",
    "latitude": "24.26"
  },
  {
    "province": "福建",
    "city": "泉州",
    "longitude": "118.36",
    "latitude": "24.56"
  },
  {
    "province": "福建",
    "city": "三明",
    "longitude": "117.36",
    "latitude": "26.13"
  },
  {
    "province": "福建",
    "city": "邵武",
    "longitude": "117.29",
    "latitude": "27.20"
  },
  {
    "province": "福建",
    "city": "石狮",
    "longitude": "118.38",
    "latitude": "24.44"
  },
  {
    "province": "福建",
    "city": "武夷山",
    "longitude": "118.02",
    "latitude": "27.46"
  },
  {
    "province": "福建",
    "city": "厦门",
    "longitude": "118.06",
    "latitude": "24.27"
  },
  {
    "province": "福建",
    "city": "永安",
    "longitude": "117.23",
    "latitude": "25.58"
  },
  {
    "province": "福建",
    "city": "漳平",
    "longitude": "117.24",
    "latitude": "25.17"
  },
  {
    "province": "福建",
    "city": "漳州",
    "longitude": "117.39",
    "latitude": "24.31"
  },
  {
    "province": "甘肃",
    "city": "兰州",
    "longitude": "103.51",
    "latitude": "36.04"
  },
  {
    "province": "甘肃",
    "city": "白银",
    "longitude": "104.12",
    "latitude": "36.33"
  },
  {
    "province": "甘肃",
    "city": "敦煌",
    "longitude": "94.41",
    "latitude": "40.08"
  },
  {
    "province": "甘肃",
    "city": "嘉峪关",
    "longitude": "98.14",
    "latitude": "39.48"
  },
  {
    "province": "甘肃",
    "city": "金昌",
    "longitude": "102.10",
    "latitude": "38.28"
  },
  {
    "province": "甘肃",
    "city": "酒泉",
    "longitude": "98.31",
    "latitude": "39.44"
  },
  {
    "province": "甘肃",
    "city": "临夏",
    "longitude": "103.12",
    "latitude": "35.37"
  },
  {
    "province": "甘肃",
    "city": "平凉",
    "longitude": "106.40",
    "latitude": "35.32"
  },
  {
    "province": "甘肃",
    "city": "天水",
    "longitude": "105.42",
    "latitude": "34.37"
  },
  {
    "province": "甘肃",
    "city": "武威",
    "longitude": "102.39",
    "latitude": "37.56"
  },
  {
    "province": "甘肃",
    "city": "西峰",
    "longitude": "107.40",
    "latitude": "35.45"
  },
  {
    "province": "甘肃",
    "city": "玉门",
    "longitude": "97.35",
    "latitude": "39.49"
  },
  {
    "province": "甘肃",
    "city": "张掖",
    "longitude": "100.26",
    "latitude": "38.56"
  },
  {
    "province": "广东",
    "city": "广州",
    "longitude": "113.14",
    "latitude": "23.08"
  },
  {
    "province": "广东",
    "city": "潮阳",
    "longitude": "116.36",
    "latitude": "23.16"
  },
  {
    "province": "广东",
    "city": "潮州",
    "longitude": "116.38",
    "latitude": "23.40"
  },
  {
    "province": "广东",
    "city": "澄海",
    "longitude": "116.46",
    "latitude": "23.28"
  },
  {
    "province": "广东",
    "city": "从化",
    "longitude": "113.33",
    "latitude": "23.33"
  },
  {
    "province": "广东",
    "city": "东莞",
    "longitude": "113.45",
    "latitude": "23.02"
  },
  {
    "province": "广东",
    "city": "恩平",
    "longitude": "112.19",
    "latitude": "22.12"
  },
  {
    "province": "广东",
    "city": "佛山",
    "longitude": "113.06",
    "latitude": "23.02"
  },
  {
    "province": "广东",
    "city": "高明",
    "longitude": "112.50",
    "latitude": "22.53"
  },
  {
    "province": "广东",
    "city": "高要",
    "longitude": "112.26",
    "latitude": "23.02"
  },
  {
    "province": "广东",
    "city": "高州",
    "longitude": "110.50",
    "latitude": "21.54"
  },
  {
    "province": "广东",
    "city": "鹤山",
    "longitude": "112.57",
    "latitude": "22.46"
  },
  {
    "province": "广东",
    "city": "河源",
    "longitude": "114.41",
    "latitude": "23.43"
  },
  {
    "province": "广东",
    "city": "花都",
    "longitude": "113.12",
    "latitude": "23.23"
  },
  {
    "province": "广东",
    "city": "化州",
    "longitude": "110.37",
    "latitude": "21.39"
  },
  {
    "province": "广东",
    "city": "惠阳",
    "longitude": "114.28",
    "latitude": "22.48"
  },
  {
    "province": "广东",
    "city": "惠州",
    "longitude": "114.22",
    "latitude": "23.05"
  },
  {
    "province": "广东",
    "city": "江门",
    "longitude": "113.04",
    "latitude": "22.35"
  },
  {
    "province": "广东",
    "city": "揭阳",
    "longitude": "116.21",
    "latitude": "22.32"
  },
  {
    "province": "广东",
    "city": "开平",
    "longitude": "112.40",
    "latitude": "22.22"
  },
  {
    "province": "广东",
    "city": "乐昌",
    "longitude": "113.21",
    "latitude": "25.09"
  },
  {
    "province": "广东",
    "city": "雷州",
    "longitude": "110.04",
    "latitude": "20.54"
  },
  {
    "province": "广东",
    "city": "廉江",
    "longitude": "110.17",
    "latitude": "21.37"
  },
  {
    "province": "广东",
    "city": "连州",
    "longitude": "112.23",
    "latitude": "24.48"
  },
  {
    "province": "广东",
    "city": "罗定",
    "longitude": "111.33",
    "latitude": "22.46"
  },
  {
    "province": "广东",
    "city": "茂名",
    "longitude": "110.53",
    "latitude": "21.40"
  },
  {
    "province": "广东",
    "city": "梅州",
    "longitude": "116.07",
    "latitude": "24.19"
  },
  {
    "province": "广东",
    "city": "南海",
    "longitude": "113.09",
    "latitude": "23.01"
  },
  {
    "province": "广东",
    "city": "番禺",
    "longitude": "113.22",
    "latitude": "22.57"
  },
  {
    "province": "广东",
    "city": "普宁",
    "longitude": "116.10",
    "latitude": "23.18"
  },
  {
    "province": "广东",
    "city": "清远",
    "longitude": "113.01",
    "latitude": "23.42"
  },
  {
    "province": "广东",
    "city": "三水",
    "longitude": "112.52",
    "latitude": "23.10"
  },
  {
    "province": "广东",
    "city": "汕头",
    "longitude": "116.41",
    "latitude": "23.22"
  },
  {
    "province": "广东",
    "city": "汕尾",
    "longitude": "115.21",
    "latitude": "22.47"
  },
  {
    "province": "广东",
    "city": "韶关",
    "longitude": "113.37",
    "latitude": "24.48"
  },
  {
    "province": "广东",
    "city": "深圳",
    "longitude": "114.061103",
    "latitude": "22.550325"
  },
  {
    "province": "广东",
    "city": "顺德",
    "longitude": "113.15",
    "latitude": "22.50"
  },
  {
    "province": "广东",
    "city": "四会",
    "longitude": "112.41",
    "latitude": "23.21"
  },
  {
    "province": "广东",
    "city": "台山",
    "longitude": "112.48",
    "latitude": "22.15"
  },
  {
    "province": "广东",
    "city": "吴川",
    "longitude": "110.47",
    "latitude": "21.26"
  },
  {
    "province": "广东",
    "city": "新会",
    "longitude": "113.01",
    "latitude": "22.32"
  },
  {
    "province": "广东",
    "city": "兴宁",
    "longitude": "115.43",
    "latitude": "24.09"
  },
  {
    "province": "广东",
    "city": "阳春",
    "longitude": "111.48",
    "latitude": "22.10"
  },
  {
    "province": "广东",
    "city": "阳江",
    "longitude": "111.58",
    "latitude": "21.50"
  },
  {
    "province": "广东",
    "city": "英德",
    "longitude": "113.22",
    "latitude": "24.10"
  },
  {
    "province": "广东",
    "city": "云浮",
    "longitude": "112.02",
    "latitude": "22.57"
  },
  {
    "province": "广东",
    "city": "增城",
    "longitude": "113.49",
    "latitude": "23.18"
  },
  {
    "province": "广东",
    "city": "湛江",
    "longitude": "110.24",
    "latitude": "21.11"
  },
  {
    "province": "广东",
    "city": "肇庆",
    "longitude": "112.27",
    "latitude": "23.03"
  },
  {
    "province": "广东",
    "city": "中山",
    "longitude": "113.22",
    "latitude": "22.31"
  },
  {
    "province": "广东",
    "city": "珠海",
    "longitude": "113.34",
    "latitude": "22.17"
  },
  {
    "province": "广西",
    "city": "南宁",
    "longitude": "108.19",
    "latitude": "22.48"
  },
  {
    "province": "广西",
    "city": "北海",
    "longitude": "109.07",
    "latitude": "21.28"
  },
  {
    "province": "广西",
    "city": "北流",
    "longitude": "110.21",
    "latitude": "22.42"
  },
  {
    "province": "广西",
    "city": "百色",
    "longitude": "106.36",
    "latitude": "23.54"
  },
  {
    "province": "广西",
    "city": "防城港",
    "longitude": "108.20",
    "latitude": "21.37"
  },
  {
    "province": "广西",
    "city": "贵港",
    "longitude": "109.36",
    "latitude": "23.06"
  },
  {
    "province": "广西",
    "city": "桂林",
    "longitude": "110.17",
    "latitude": "25.17"
  },
  {
    "province": "广西",
    "city": "桂平",
    "longitude": "110.04",
    "latitude": "23.22"
  },
  {
    "province": "广西",
    "city": "河池",
    "longitude": "108.03",
    "latitude": "24.42"
  },
  {
    "province": "广西",
    "city": "合山",
    "longitude": "108.52",
    "latitude": "23.47"
  },
  {
    "province": "广西",
    "city": "柳州",
    "longitude": "109.24",
    "latitude": "23.19"
  },
  {
    "province": "广西",
    "city": "赁祥",
    "longitude": "106.44",
    "latitude": "22.07"
  },
  {
    "province": "广西",
    "city": "钦州",
    "longitude": "108.37",
    "latitude": "21.57"
  },
  {
    "province": "广西",
    "city": "梧州",
    "longitude": "111.20",
    "latitude": "23.29"
  },
  {
    "province": "广西",
    "city": "玉林",
    "longitude": "110.09",
    "latitude": "22.38"
  },
  {
    "province": "广西",
    "city": "宜州",
    "longitude": "108.40",
    "latitude": "24.28"
  },
  {
    "province": "贵州",
    "city": "贵阳",
    "longitude": "106.42",
    "latitude": "26.35"
  },
  {
    "province": "贵州",
    "city": "安顺",
    "longitude": "105.55",
    "latitude": "26.14"
  },
  {
    "province": "贵州",
    "city": "毕节",
    "longitude": "105.18",
    "latitude": "27.18"
  },
  {
    "province": "贵州",
    "city": "赤水",
    "longitude": "105.42",
    "latitude": "28.34"
  },
  {
    "province": "贵州",
    "city": "都匀",
    "longitude": "107.31",
    "latitude": "26.15"
  },
  {
    "province": "贵州",
    "city": "凯里",
    "longitude": "107.58",
    "latitude": "26.35"
  },
  {
    "province": "贵州",
    "city": "六盘水",
    "longitude": "104.50",
    "latitude": "26.35"
  },
  {
    "province": "贵州",
    "city": "清镇",
    "longitude": "106.27",
    "latitude": "26.33"
  },
  {
    "province": "贵州",
    "city": "铜仁",
    "longitude": "109.12",
    "latitude": "27.43"
  },
  {
    "province": "贵州",
    "city": "兴义",
    "longitude": "104.53",
    "latitude": "25.05"
  },
  {
    "province": "贵州",
    "city": "遵义",
    "longitude": "106.55",
    "latitude": "27.42"
  },
  {
    "province": "海南",
    "city": "海口",
    "longitude": "110.20",
    "latitude": "20.02"
  },
  {
    "province": "海南",
    "city": "儋州",
    "longitude": "109.34",
    "latitude": "19.31"
  },
  {
    "province": "海南",
    "city": "琼海",
    "longitude": "110.28",
    "latitude": "19.14"
  },
  {
    "province": "海南",
    "city": "琼山",
    "longitude": "110.21",
    "latitude": "19.59"
  },
  {
    "province": "海南",
    "city": "三亚",
    "longitude": "109.31",
    "latitude": "18.14"
  },
  {
    "province": "海南",
    "city": "通什",
    "longitude": "109.31",
    "latitude": "18.46"
  },
  {
    "province": "河北",
    "city": "石家庄",
    "longitude": "114.30",
    "latitude": "38.02"
  },
  {
    "province": "河北",
    "city": "安国",
    "longitude": "115.20",
    "latitude": "38.24"
  },
  {
    "province": "河北",
    "city": "保定",
    "longitude": "115.30",
    "latitude": "38.51"
  },
  {
    "province": "河北",
    "city": "霸州",
    "longitude": "116.24",
    "latitude": "39.06"
  },
  {
    "province": "河北",
    "city": "泊头",
    "longitude": "116.34",
    "latitude": "38.04"
  },
  {
    "province": "河北",
    "city": "沧州",
    "longitude": "116.52",
    "latitude": "38.18"
  },
  {
    "province": "河北",
    "city": "承德",
    "longitude": "117.57",
    "latitude": "40.59"
  },
  {
    "province": "河北",
    "city": "定州",
    "longitude": "115.00",
    "latitude": "38.30"
  },
  {
    "province": "河北",
    "city": "丰南",
    "longitude": "118.06",
    "latitude": "39.34"
  },
  {
    "province": "河北",
    "city": "高碑店",
    "longitude": "115.51",
    "latitude": "39.20"
  },
  {
    "province": "河北",
    "city": "蒿城",
    "longitude": "114.50",
    "latitude": "38.02"
  },
  {
    "province": "河北",
    "city": "邯郸",
    "longitude": "114.28",
    "latitude": "36.36"
  },
  {
    "province": "河北",
    "city": "河间",
    "longitude": "116.05",
    "latitude": "38.26"
  },
  {
    "province": "河北",
    "city": "衡水",
    "longitude": "115.42",
    "latitude": "37.44"
  },
  {
    "province": "河北",
    "city": "黄骅",
    "longitude": "117.21",
    "latitude": "38.21"
  },
  {
    "province": "河北",
    "city": "晋州",
    "longitude": "115.02",
    "latitude": "38.02"
  },
  {
    "province": "河北",
    "city": "冀州",
    "longitude": "115.33",
    "latitude": "37.34"
  },
  {
    "province": "河北",
    "city": "廓坊",
    "longitude": "116.42",
    "latitude": "39.31"
  },
  {
    "province": "河北",
    "city": "鹿泉",
    "longitude": "114.19",
    "latitude": "38.04"
  },
  {
    "province": "河北",
    "city": "南宫",
    "longitude": "115.23",
    "latitude": "37.22"
  },
  {
    "province": "河北",
    "city": "秦皇岛",
    "longitude": "119.35",
    "latitude": "39.55"
  },
  {
    "province": "河北",
    "city": "任丘",
    "longitude": "116.07",
    "latitude": "38.42"
  },
  {
    "province": "河北",
    "city": "三河",
    "longitude": "117.04",
    "latitude": "39.58"
  },
  {
    "province": "河北",
    "city": "沙河",
    "longitude": "114.30",
    "latitude": "36.51"
  },
  {
    "province": "河北",
    "city": "深州",
    "longitude": "115.32",
    "latitude": "38.01"
  },
  {
    "province": "河北",
    "city": "唐山",
    "longitude": "118.11",
    "latitude": "39.36"
  },
  {
    "province": "河北",
    "city": "武安",
    "longitude": "114.11",
    "latitude": "36.42"
  },
  {
    "province": "河北",
    "city": "邢台",
    "longitude": "114.30",
    "latitude": "37.04"
  },
  {
    "province": "河北",
    "city": "辛集",
    "longitude": "115.12",
    "latitude": "37.54"
  },
  {
    "province": "河北",
    "city": "新乐",
    "longitude": "114.41",
    "latitude": "38.20"
  },
  {
    "province": "河北",
    "city": "张家口",
    "longitude": "114.53",
    "latitude": "40.48"
  },
  {
    "province": "河北",
    "city": "涿州",
    "longitude": "115.59",
    "latitude": "39.29"
  },
  {
    "province": "河北",
    "city": "遵化",
    "longitude": "117.58",
    "latitude": "40.11"
  },
  {
    "province": "河南",
    "city": "郑州",
    "longitude": "11340",
    "latitude": "34.46"
  },
  {
    "province": "河南",
    "city": "安阳",
    "longitude": "114.21",
    "latitude": "36.06"
  },
  {
    "province": "河南",
    "city": "长葛",
    "longitude": "113.47",
    "latitude": "34.12"
  },
  {
    "province": "河南",
    "city": "登封",
    "longitude": "113.02",
    "latitude": "34.27"
  },
  {
    "province": "河南",
    "city": "邓州",
    "longitude": "112.05",
    "latitude": "32.42"
  },
  {
    "province": "河南",
    "city": "巩义",
    "longitude": "112.58",
    "latitude": "34.46"
  },
  {
    "province": "河南",
    "city": "鹤壁",
    "longitude": "114.11",
    "latitude": "35.54"
  },
  {
    "province": "河南",
    "city": "辉县",
    "longitude": "113.47",
    "latitude": "35.27"
  },
  {
    "province": "河南",
    "city": "焦作",
    "longitude": "113.12",
    "latitude": "35.14"
  },
  {
    "province": "河南",
    "city": "济源",
    "longitude": "112.35",
    "latitude": "35.04"
  },
  {
    "province": "河南",
    "city": "开封",
    "longitude": "114.21",
    "latitude": "34.47"
  },
  {
    "province": "河南",
    "city": "灵宝",
    "longitude": "110.52",
    "latitude": "34.31"
  },
  {
    "province": "河南",
    "city": "林州",
    "longitude": "113.49",
    "latitude": "36.03"
  },
  {
    "province": "河南",
    "city": "漯河",
    "longitude": "114.02",
    "latitude": "33.33"
  },
  {
    "province": "河南",
    "city": "洛阳",
    "longitude": "112.27",
    "latitude": "34.41"
  },
  {
    "province": "河南",
    "city": "南阳",
    "longitude": "112.32",
    "latitude": "33.00"
  },
  {
    "province": "河南",
    "city": "平顶山",
    "longitude": "113.17",
    "latitude": "33.44"
  },
  {
    "province": "河南",
    "city": "濮阳",
    "longitude": "115.01",
    "latitude": "35.44"
  },
  {
    "province": "河南",
    "city": "沁阳",
    "longitude": "112.57",
    "latitude": "35.05"
  },
  {
    "province": "河南",
    "city": "汝州",
    "longitude": "112.50",
    "latitude": "34.09"
  },
  {
    "province": "河南",
    "city": "三门峡",
    "longitude": "111.12",
    "latitude": "34.47"
  },
  {
    "province": "河南",
    "city": "商丘",
    "longitude": "115.38",
    "latitude": "34.26"
  },
  {
    "province": "河南",
    "city": "卫辉",
    "longitude": "114.03",
    "latitude": "35.24"
  },
  {
    "province": "河南",
    "city": "舞钢",
    "longitude": "113.30",
    "latitude": "33.17"
  },
  {
    "province": "河南",
    "city": "项城",
    "longitude": "114.54",
    "latitude": "33.26"
  },
  {
    "province": "河南",
    "city": "荥阳",
    "longitude": "113.21",
    "latitude": "34.46"
  },
  {
    "province": "河南",
    "city": "新密",
    "longitude": "113.22",
    "latitude": "34.31"
  },
  {
    "province": "河南",
    "city": "新乡",
    "longitude": "113.52",
    "latitude": "35.18"
  },
  {
    "province": "河南",
    "city": "信阳",
    "longitude": "114.04",
    "latitude": "32.07"
  },
  {
    "province": "河南",
    "city": "新郑",
    "longitude": "113.43",
    "latitude": "34.24"
  },
  {
    "province": "河南",
    "city": "许昌",
    "longitude": "113.49",
    "latitude": "34.01"
  },
  {
    "province": "河南",
    "city": "偃师",
    "longitude": "112.47",
    "latitude": "34.43"
  },
  {
    "province": "河南",
    "city": "义马",
    "longitude": "111.55",
    "latitude": "34.43"
  },
  {
    "province": "河南",
    "city": "禹州",
    "longitude": "113.28",
    "latitude": "34.09"
  },
  {
    "province": "河南",
    "city": "周口",
    "longitude": "114.38",
    "latitude": "33.37"
  },
  {
    "province": "河南",
    "city": "驻马店",
    "longitude": "114.01",
    "latitude": "32.58"
  },
  {
    "province": "黑龙江",
    "city": "哈尔滨",
    "longitude": "126.36",
    "latitude": "45.44"
  },
  {
    "province": "黑龙江",
    "city": "阿城",
    "longitude": "126.58",
    "latitude": "45.32"
  },
  {
    "province": "黑龙江",
    "city": "安达",
    "longitude": "125.18",
    "latitude": "46.24"
  },
  {
    "province": "黑龙江",
    "city": "北安",
    "longitude": "126.31",
    "latitude": "48.15"
  },
  {
    "province": "黑龙江",
    "city": "大庆",
    "longitude": "125.01",
    "latitude": "46.36"
  },
  {
    "province": "黑龙江",
    "city": "富锦",
    "longitude": "132.02",
    "latitude": "47.15"
  },
  {
    "province": "黑龙江",
    "city": "海林",
    "longitude": "129.21",
    "latitude": "44.35"
  },
  {
    "province": "黑龙江",
    "city": "海伦",
    "longitude": "126.57",
    "latitude": "47.28"
  },
  {
    "province": "黑龙江",
    "city": "鹤岗",
    "longitude": "130.16",
    "latitude": "47.20"
  },
  {
    "province": "黑龙江",
    "city": "黑河",
    "longitude": "127.29",
    "latitude": "50.14"
  },
  {
    "province": "黑龙江",
    "city": "佳木斯",
    "longitude": "130.22",
    "latitude": "46.47"
  },
  {
    "province": "黑龙江",
    "city": "鸡西",
    "longitude": "130.57",
    "latitude": "45.17"
  },
  {
    "province": "黑龙江",
    "city": "密山",
    "longitude": "131.50",
    "latitude": "45.32"
  },
  {
    "province": "黑龙江",
    "city": "牡丹江",
    "longitude": "129.36",
    "latitude": "44.35"
  },
  {
    "province": "黑龙江",
    "city": "讷河",
    "longitude": "124.51",
    "latitude": "48.29"
  },
  {
    "province": "黑龙江",
    "city": "宁安",
    "longitude": "129.28",
    "latitude": "44.21"
  },
  {
    "province": "黑龙江",
    "city": "齐齐哈尔",
    "longitude": "123.57",
    "latitude": "47.20"
  },
  {
    "province": "黑龙江",
    "city": "七台河",
    "longitude": "130.49",
    "latitude": "45.48"
  },
  {
    "province": "黑龙江",
    "city": "双城",
    "longitude": "126.15",
    "latitude": "45.22"
  },
  {
    "province": "黑龙江",
    "city": "尚志",
    "longitude": "127.55",
    "latitude": "45.14"
  },
  {
    "province": "黑龙江",
    "city": "双鸭山",
    "longitude": "131.11",
    "latitude": "46.38"
  },
  {
    "province": "黑龙江",
    "city": "绥芬河",
    "longitude": "131.11",
    "latitude": "44.25"
  },
  {
    "province": "黑龙江",
    "city": "绥化",
    "longitude": "126.59",
    "latitude": "46.38"
  },
  {
    "province": "黑龙江",
    "city": "铁力",
    "longitude": "128.01",
    "latitude": "46.59"
  },
  {
    "province": "黑龙江",
    "city": "同江",
    "longitude": "132.30",
    "latitude": "47.39"
  },
  {
    "province": "黑龙江",
    "city": "五常",
    "longitude": "127.11",
    "latitude": "44.55"
  },
  {
    "province": "黑龙江",
    "city": "五大连池",
    "longitude": "126.07",
    "latitude": "48.38"
  },
  {
    "province": "黑龙江",
    "city": "伊春",
    "longitude": "128.56",
    "latitude": "47.42"
  },
  {
    "province": "黑龙江",
    "city": "肇东",
    "longitude": "125.58",
    "latitude": "46.04"
  },
  {
    "province": "湖北",
    "city": "武汉",
    "longitude": "114.17",
    "latitude": "30.35"
  },
  {
    "province": "湖北",
    "city": "安陆",
    "longitude": "113.41",
    "latitude": "31.15"
  },
  {
    "province": "湖北",
    "city": "当阳",
    "longitude": "111.47",
    "latitude": "30.50"
  },
  {
    "province": "湖北",
    "city": "丹江口",
    "longitude": "108.30",
    "latitude": "32.33"
  },
  {
    "province": "湖北",
    "city": "大冶",
    "longitude": "114.58",
    "latitude": "30.06"
  },
  {
    "province": "湖北",
    "city": "恩施",
    "longitude": "109.29",
    "latitude": "30.16"
  },
  {
    "province": "湖北",
    "city": "鄂州",
    "longitude": "114.52",
    "latitude": "30.23"
  },
  {
    "province": "湖北",
    "city": "广水",
    "longitude": "113.48",
    "latitude": "31.37"
  },
  {
    "province": "湖北",
    "city": "洪湖",
    "longitude": "113.27",
    "latitude": "29.48"
  },
  {
    "province": "湖北",
    "city": "黄石",
    "longitude": "115.06",
    "latitude": "30.12"
  },
  {
    "province": "湖北",
    "city": "黄州",
    "longitude": "114.52",
    "latitude": "30.27"
  },
  {
    "province": "湖北",
    "city": "荆门",
    "longitude": "112.12",
    "latitude": "31.02"
  },
  {
    "province": "湖北",
    "city": "荆沙",
    "longitude": "112.16",
    "latitude": "30.18"
  },
  {
    "province": "湖北",
    "city": "老河口",
    "longitude": "111.40",
    "latitude": "32.23"
  },
  {
    "province": "湖北",
    "city": "利川",
    "longitude": "108.56",
    "latitude": "30.18"
  },
  {
    "province": "湖北",
    "city": "麻城",
    "longitude": "115.01",
    "latitude": "31.10"
  },
  {
    "province": "湖北",
    "city": "浦圻",
    "longitude": "113.51",
    "latitude": "29.42"
  },
  {
    "province": "湖北",
    "city": "潜江",
    "longitude": "112.53",
    "latitude": "30.26"
  },
  {
    "province": "湖北",
    "city": "石首",
    "longitude": "112.24",
    "latitude": "29.43"
  },
  {
    "province": "湖北",
    "city": "十堰",
    "longitude": "110.47",
    "latitude": "32.40"
  },
  {
    "province": "湖北",
    "city": "随州",
    "longitude": "113.22",
    "latitude": "31.42"
  },
  {
    "province": "湖北",
    "city": "天门",
    "longitude": "113.10",
    "latitude": "60.39"
  },
  {
    "province": "湖北",
    "city": "武穴",
    "longitude": "115.33",
    "latitude": "29.51"
  },
  {
    "province": "湖北",
    "city": "襄樊",
    "longitude": "112.08",
    "latitude": "32.02"
  },
  {
    "province": "湖北",
    "city": "咸宁",
    "longitude": "114.17",
    "latitude": "29.53"
  },
  {
    "province": "湖北",
    "city": "仙桃",
    "longitude": "113.27",
    "latitude": "30.22"
  },
  {
    "province": "湖北",
    "city": "孝感",
    "longitude": "113.54",
    "latitude": "30.56"
  },
  {
    "province": "湖北",
    "city": "宜昌",
    "longitude": "111.17",
    "latitude": "30.42"
  },
  {
    "province": "湖北",
    "city": "宜城",
    "longitude": "112.15",
    "latitude": "31.42"
  },
  {
    "province": "湖北",
    "city": "应城",
    "longitude": "113.33",
    "latitude": "30.57"
  },
  {
    "province": "湖北",
    "city": "枣阳",
    "longitude": "112.44",
    "latitude": "32.07"
  },
  {
    "province": "湖北",
    "city": "枝城",
    "longitude": "111.27",
    "latitude": "30.23"
  },
  {
    "province": "湖北",
    "city": "钟祥",
    "longitude": "112.34",
    "latitude": "31.10"
  },
  {
    "province": "湖南",
    "city": "长沙",
    "longitude": "112.59",
    "latitude": "28.12"
  },
  {
    "province": "湖南",
    "city": "常德",
    "longitude": "111.51",
    "latitude": "29.02"
  },
  {
    "province": "湖南",
    "city": "郴州",
    "longitude": "113.02",
    "latitude": "25.46"
  },
  {
    "province": "湖南",
    "city": "衡阳",
    "longitude": "112.37",
    "latitude": "26.53"
  },
  {
    "province": "湖南",
    "city": "洪江",
    "longitude": "109.59",
    "latitude": "27.07"
  },
  {
    "province": "湖南",
    "city": "怀化",
    "longitude": "109.58",
    "latitude": "27.33"
  },
  {
    "province": "湖南",
    "city": "津市",
    "longitude": "111.52",
    "latitude": "29.38"
  },
  {
    "province": "湖南",
    "city": "吉首",
    "longitude": "109.43",
    "latitude": "28.18"
  },
  {
    "province": "湖南",
    "city": "耒阳",
    "longitude": "112.51",
    "latitude": "26.24"
  },
  {
    "province": "湖南",
    "city": "冷水江",
    "longitude": "111.26",
    "latitude": "27.42"
  },
  {
    "province": "湖南",
    "city": "冷水滩",
    "longitude": "111.35",
    "latitude": "26.26"
  },
  {
    "province": "湖南",
    "city": "涟源",
    "longitude": "111.41",
    "latitude": "27.41"
  },
  {
    "province": "湖南",
    "city": "醴陵",
    "longitude": "113.30",
    "latitude": "27.40"
  },
  {
    "province": "湖南",
    "city": "临湘",
    "longitude": "113.27",
    "latitude": "29.29"
  },
  {
    "province": "湖南",
    "city": "浏阳",
    "longitude": "113.37",
    "latitude": "28.09"
  },
  {
    "province": "湖南",
    "city": "娄底",
    "longitude": "111.59",
    "latitude": "27.44"
  },
  {
    "province": "湖南",
    "city": "汨罗",
    "longitude": "113.03",
    "latitude": "28.49"
  },
  {
    "province": "湖南",
    "city": "韶山",
    "longitude": "112.29",
    "latitude": "27.54"
  },
  {
    "province": "湖南",
    "city": "邵阳",
    "longitude": "111.28",
    "latitude": "27.14"
  },
  {
    "province": "湖南",
    "city": "武冈",
    "longitude": "110.37",
    "latitude": "26.43"
  },
  {
    "province": "湖南",
    "city": "湘潭",
    "longitude": "112.53",
    "latitude": "27.52"
  },
  {
    "province": "湖南",
    "city": "湘乡",
    "longitude": "112.31",
    "latitude": "27.44"
  },
  {
    "province": "湖南",
    "city": "益阳",
    "longitude": "112.20",
    "latitude": "28.36"
  },
  {
    "province": "湖南",
    "city": "永州",
    "longitude": "111.37",
    "latitude": "26.13"
  },
  {
    "province": "湖南",
    "city": "沅江",
    "longitude": "112.22",
    "latitude": "28.50"
  },
  {
    "province": "湖南",
    "city": "岳阳",
    "longitude": "113.06",
    "latitude": "29.22"
  },
  {
    "province": "湖南",
    "city": "张家界",
    "longitude": "110.29",
    "latitude": "29.08"
  },
  {
    "province": "湖南",
    "city": "株洲",
    "longitude": "113.09",
    "latitude": "27.51"
  },
  {
    "province": "湖南",
    "city": "资兴",
    "longitude": "113.13",
    "latitude": "25.58"
  },
  {
    "province": "吉林",
    "city": "长春",
    "longitude": "125.19",
    "latitude": "43.54"
  },
  {
    "province": "吉林",
    "city": "白城",
    "longitude": "122.50",
    "latitude": "45.38"
  },
  {
    "province": "吉林",
    "city": "白山",
    "longitude": "126.26",
    "latitude": "41.56"
  },
  {
    "province": "吉林",
    "city": "大安",
    "longitude": "124.18",
    "latitude": "45.30"
  },
  {
    "province": "吉林",
    "city": "德惠",
    "longitude": "125.42",
    "latitude": "44.32"
  },
  {
    "province": "吉林",
    "city": "敦化",
    "longitude": "128.13",
    "latitude": "43.22"
  },
  {
    "province": "吉林",
    "city": "公主岭",
    "longitude": "124.49",
    "latitude": "43.31"
  },
  {
    "province": "吉林",
    "city": "和龙",
    "longitude": "129.00",
    "latitude": "42.32"
  },
  {
    "province": "吉林",
    "city": "桦甸",
    "longitude": "126.44",
    "latitude": "42.58"
  },
  {
    "province": "吉林",
    "city": "珲春",
    "longitude": "130.22",
    "latitude": "42.52"
  },
  {
    "province": "吉林",
    "city": "集安",
    "longitude": "126.11",
    "latitude": "41.08"
  },
  {
    "province": "吉林",
    "city": "蛟河",
    "longitude": "127.21",
    "latitude": "43.42"
  },
  {
    "province": "吉林",
    "city": "吉林",
    "longitude": "126.33",
    "latitude": "43.52"
  },
  {
    "province": "吉林",
    "city": "九台",
    "longitude": "125.51",
    "latitude": "44.09"
  },
  {
    "province": "吉林",
    "city": "辽源",
    "longitude": "125.09",
    "latitude": "42.54"
  },
  {
    "province": "吉林",
    "city": "临江",
    "longitude": "126.53",
    "latitude": "41.49"
  },
  {
    "province": "吉林",
    "city": "龙井",
    "longitude": "129.26",
    "latitude": "42.46"
  },
  {
    "province": "吉林",
    "city": "梅河口",
    "longitude": "125.40",
    "latitude": "42.32"
  },
  {
    "province": "吉林",
    "city": "舒兰",
    "longitude": "126.57",
    "latitude": "44.24"
  },
  {
    "province": "吉林",
    "city": "四平",
    "longitude": "124.22",
    "latitude": "43.10"
  },
  {
    "province": "吉林",
    "city": "松原",
    "longitude": "124.49",
    "latitude": "45.11"
  },
  {
    "province": "吉林",
    "city": "洮南",
    "longitude": "122.47",
    "latitude": "45.20"
  },
  {
    "province": "吉林",
    "city": "通化",
    "longitude": "125.56",
    "latitude": "41.43"
  },
  {
    "province": "吉林",
    "city": "图们",
    "longitude": "129.51",
    "latitude": "42.57"
  },
  {
    "province": "吉林",
    "city": "延吉",
    "longitude": "129.30",
    "latitude": "42.54"
  },
  {
    "province": "吉林",
    "city": "愉树",
    "longitude": "126.32",
    "latitude": "44.49"
  },
  {
    "province": "江苏",
    "city": "南京",
    "longitude": "118.46",
    "latitude": "32.03"
  },
  {
    "province": "江苏",
    "city": "常熟",
    "longitude": "120.43",
    "latitude": "31.39"
  },
  {
    "province": "江苏",
    "city": "常州",
    "longitude": "119.58",
    "latitude": "31.47"
  },
  {
    "province": "江苏",
    "city": "丹阳",
    "longitude": "119.32",
    "latitude": "32.00"
  },
  {
    "province": "江苏",
    "city": "东台",
    "longitude": "120.19",
    "latitude": "32.51"
  },
  {
    "province": "江苏",
    "city": "高邮",
    "longitude": "119.27",
    "latitude": "32.47"
  },
  {
    "province": "江苏",
    "city": "海门",
    "longitude": "121.09",
    "latitude": "31.53"
  },
  {
    "province": "江苏",
    "city": "淮安",
    "longitude": "119.09",
    "latitude": "33.30"
  },
  {
    "province": "江苏",
    "city": "淮阴",
    "longitude": "119.02",
    "latitude": "33.36"
  },
  {
    "province": "江苏",
    "city": "江都",
    "longitude": "119.32",
    "latitude": "32.26"
  },
  {
    "province": "江苏",
    "city": "姜堰",
    "longitude": "120.08",
    "latitude": "32.34"
  },
  {
    "province": "江苏",
    "city": "江阴",
    "longitude": "120.17",
    "latitude": "31.54"
  },
  {
    "province": "江苏",
    "city": "靖江",
    "longitude": "120.17",
    "latitude": "32.02"
  },
  {
    "province": "江苏",
    "city": "金坛",
    "longitude": "119.33",
    "latitude": "31.46"
  },
  {
    "province": "江苏",
    "city": "昆山",
    "longitude": "120.57",
    "latitude": "31.23"
  },
  {
    "province": "江苏",
    "city": "连去港",
    "longitude": "119.10",
    "latitude": "34.36"
  },
  {
    "province": "江苏",
    "city": "溧阳",
    "longitude": "119.29",
    "latitude": "31.26"
  },
  {
    "province": "江苏",
    "city": "南通",
    "longitude": "120.51",
    "latitude": "32.01"
  },
  {
    "province": "江苏",
    "city": "邳州",
    "longitude": "117.59",
    "latitude": "34.19"
  },
  {
    "province": "江苏",
    "city": "启乐",
    "longitude": "121.39",
    "latitude": "31.48"
  },
  {
    "province": "江苏",
    "city": "如皋",
    "longitude": "120.33",
    "latitude": "32.23"
  },
  {
    "province": "江苏",
    "city": "宿迁",
    "longitude": "118.18",
    "latitude": "33.58"
  },
  {
    "province": "江苏",
    "city": "苏州",
    "longitude": "120.37",
    "latitude": "31.19"
  },
  {
    "province": "江苏",
    "city": "太仓",
    "longitude": "121.06",
    "latitude": "31.27"
  },
  {
    "province": "江苏",
    "city": "泰兴",
    "longitude": "120.01",
    "latitude": "32.10"
  },
  {
    "province": "江苏",
    "city": "泰州",
    "longitude": "119.54",
    "latitude": "32.30"
  },
  {
    "province": "江苏",
    "city": "通州",
    "longitude": "121.03",
    "latitude": "32.05"
  },
  {
    "province": "江苏",
    "city": "吴江",
    "longitude": "120.39",
    "latitude": "31.10"
  },
  {
    "province": "江苏",
    "city": "无锡",
    "longitude": "120.18",
    "latitude": "31.34"
  },
  {
    "province": "江苏",
    "city": "兴化",
    "longitude": "119.50",
    "latitude": "32.56"
  },
  {
    "province": "江苏",
    "city": "新沂",
    "longitude": "118.20",
    "latitude": "34.22"
  },
  {
    "province": "江苏",
    "city": "徐州",
    "longitude": "117.11",
    "latitude": "34.15"
  },
  {
    "province": "江苏",
    "city": "盐在",
    "longitude": "120.08",
    "latitude": "33.22"
  },
  {
    "province": "江苏",
    "city": "扬中",
    "longitude": "119.49",
    "latitude": "32.14"
  },
  {
    "province": "江苏",
    "city": "扬州",
    "longitude": "119.26",
    "latitude": "32.23"
  },
  {
    "province": "江苏",
    "city": "宜兴",
    "longitude": "119.49",
    "latitude": "31.21"
  },
  {
    "province": "江苏",
    "city": "仪征",
    "longitude": "119.10",
    "latitude": "32.16"
  },
  {
    "province": "江苏",
    "city": "张家港",
    "longitude": "120.32",
    "latitude": "31.52"
  },
  {
    "province": "江苏",
    "city": "镇江",
    "longitude": "119.27",
    "latitude": "32.11"
  },
  {
    "province": "江西",
    "city": "南昌",
    "longitude": "115.55",
    "latitude": "28.40"
  },
  {
    "province": "江西",
    "city": "德兴",
    "longitude": "117.35",
    "latitude": "28.57"
  },
  {
    "province": "江西",
    "city": "丰城",
    "longitude": "115.48",
    "latitude": "28.12"
  },
  {
    "province": "江西",
    "city": "赣州",
    "longitude": "114.56",
    "latitude": "28.52"
  },
  {
    "province": "江西",
    "city": "高安",
    "longitude": "115.22",
    "latitude": "28.25"
  },
  {
    "province": "江西",
    "city": "吉安",
    "longitude": "114.58",
    "latitude": "27.07"
  },
  {
    "province": "江西",
    "city": "景德镇",
    "longitude": "117.13",
    "latitude": "29.17"
  },
  {
    "province": "江西",
    "city": "井冈山",
    "longitude": "114.10",
    "latitude": "26.34"
  },
  {
    "province": "江西",
    "city": "九江",
    "longitude": "115.58",
    "latitude": "29.43"
  },
  {
    "province": "江西",
    "city": "乐平",
    "longitude": "117.08",
    "latitude": "28.58"
  },
  {
    "province": "江西",
    "city": "临川",
    "longitude": "116.21",
    "latitude": "27.59"
  },
  {
    "province": "江西",
    "city": "萍乡",
    "longitude": "113.50",
    "latitude": "27.37"
  },
  {
    "province": "江西",
    "city": "瑞昌",
    "longitude": "115.38",
    "latitude": "29.40"
  },
  {
    "province": "江西",
    "city": "瑞金",
    "longitude": "116.01",
    "latitude": "25.53"
  },
  {
    "province": "江西",
    "city": "上饶",
    "longitude": "117.58",
    "latitude": "25.27"
  },
  {
    "province": "江西",
    "city": "新余",
    "longitude": "114.56",
    "latitude": "27.48"
  },
  {
    "province": "江西",
    "city": "宜春",
    "longitude": "114.23",
    "latitude": "27.47"
  },
  {
    "province": "江西",
    "city": "鹰潭",
    "longitude": "117.03",
    "latitude": "28.14"
  },
  {
    "province": "江西",
    "city": "樟树",
    "longitude": "115.32",
    "latitude": "28.03"
  },
  {
    "province": "辽宁",
    "city": "沈阳",
    "longitude": "123.25",
    "latitude": "41.48"
  },
  {
    "province": "辽宁",
    "city": "鞍山",
    "longitude": "123.00",
    "latitude": "41.07"
  },
  {
    "province": "辽宁",
    "city": "北票",
    "longitude": "120.47",
    "latitude": "41.48"
  },
  {
    "province": "辽宁",
    "city": "本溪",
    "longitude": "123.46",
    "latitude": "41.18"
  },
  {
    "province": "辽宁",
    "city": "朝阳",
    "longitude": "120.27",
    "latitude": "41.34"
  },
  {
    "province": "辽宁",
    "city": "大连",
    "longitude": "121.36",
    "latitude": "38.55"
  },
  {
    "province": "辽宁",
    "city": "丹东",
    "longitude": "124.22",
    "latitude": "40.08"
  },
  {
    "province": "辽宁",
    "city": "大石桥",
    "longitude": "122.31",
    "latitude": "40.37"
  },
  {
    "province": "辽宁",
    "city": "东港",
    "longitude": "124.08",
    "latitude": "39.53"
  },
  {
    "province": "辽宁",
    "city": "凤城",
    "longitude": "124.02",
    "latitude": "40.28"
  },
  {
    "province": "辽宁",
    "city": "抚顺",
    "longitude": "123.54",
    "latitude": "41.51"
  },
  {
    "province": "辽宁",
    "city": "阜新",
    "longitude": "121.39",
    "latitude": "42.01"
  },
  {
    "province": "辽宁",
    "city": "盖州",
    "longitude": "122.21",
    "latitude": "40.24"
  },
  {
    "province": "辽宁",
    "city": "海城",
    "longitude": "122.43",
    "latitude": "40.51"
  },
  {
    "province": "辽宁",
    "city": "葫芦岛",
    "longitude": "120.51",
    "latitude": "40.45"
  },
  {
    "province": "辽宁",
    "city": "锦州",
    "longitude": "121.09",
    "latitude": "41.07"
  },
  {
    "province": "辽宁",
    "city": "开原",
    "longitude": "124.02",
    "latitude": "42.32"
  },
  {
    "province": "辽宁",
    "city": "辽阳",
    "longitude": "123.12",
    "latitude": "41.16"
  },
  {
    "province": "辽宁",
    "city": "凌海",
    "longitude": "121.21",
    "latitude": "41.10"
  },
  {
    "province": "辽宁",
    "city": "凌源",
    "longitude": "119.22",
    "latitude": "41.14"
  },
  {
    "province": "辽宁",
    "city": "盘锦",
    "longitude": "122.03",
    "latitude": "41.07"
  },
  {
    "province": "辽宁",
    "city": "普兰店",
    "longitude": "121.58",
    "latitude": "39.23"
  },
  {
    "province": "辽宁",
    "city": "铁法",
    "longitude": "123.32",
    "latitude": "42.28"
  },
  {
    "province": "辽宁",
    "city": "铁岭",
    "longitude": "123.51",
    "latitude": "42.18"
  },
  {
    "province": "辽宁",
    "city": "瓦房店",
    "longitude": "122.00",
    "latitude": "39.37"
  },
  {
    "province": "辽宁",
    "city": "兴城",
    "longitude": "120.41",
    "latitude": "40.37"
  },
  {
    "province": "辽宁",
    "city": "新民",
    "longitude": "122.49",
    "latitude": "41.59"
  },
  {
    "province": "辽宁",
    "city": "营口",
    "longitude": "122.13",
    "latitude": "40.39"
  },
  {
    "province": "辽宁",
    "city": "庄河",
    "longitude": "122.58",
    "latitude": "39.41"
  },
  {
    "province": "内蒙",
    "city": "呼和浩特",
    "longitude": "111.41",
    "latitude": "40.48"
  },
  {
    "province": "内蒙",
    "city": "包头",
    "longitude": "109.49",
    "latitude": "40.39"
  },
  {
    "province": "内蒙",
    "city": "赤峰",
    "longitude": "118.58",
    "latitude": "42.17"
  },
  {
    "province": "内蒙",
    "city": "东胜",
    "longitude": "109.59",
    "latitude": "39.48"
  },
  {
    "province": "内蒙",
    "city": "二连浩特",
    "longitude": "111.58",
    "latitude": "43.38"
  },
  {
    "province": "内蒙",
    "city": "额尔古纳",
    "longitude": "120.11",
    "latitude": "50.13"
  },
  {
    "province": "内蒙",
    "city": "丰镇",
    "longitude": "113.09",
    "latitude": "40.27"
  },
  {
    "province": "内蒙",
    "city": "根河",
    "longitude": "121.29",
    "latitude": "50.48"
  },
  {
    "province": "内蒙",
    "city": "海拉尔",
    "longitude": "119.39",
    "latitude": "49.12"
  },
  {
    "province": "内蒙",
    "city": "霍林郭勒",
    "longitude": "119.38",
    "latitude": "45.32"
  },
  {
    "province": "内蒙",
    "city": "集宁",
    "longitude": "113.06",
    "latitude": "41.02"
  },
  {
    "province": "内蒙",
    "city": "临河",
    "longitude": "107.22",
    "latitude": "40.46"
  },
  {
    "province": "内蒙",
    "city": "满洲里",
    "longitude": "117.23",
    "latitude": "49.35"
  },
  {
    "province": "内蒙",
    "city": "通辽",
    "longitude": "122.16",
    "latitude": "43.37"
  },
  {
    "province": "内蒙",
    "city": "乌兰浩特",
    "longitude": "122.03",
    "latitude": "46.03"
  },
  {
    "province": "内蒙",
    "city": "乌海",
    "longitude": "106.48",
    "latitude": "39.40"
  },
  {
    "province": "内蒙",
    "city": "锡林浩特",
    "longitude": "116.03",
    "latitude": "43.57"
  },
  {
    "province": "内蒙",
    "city": "牙克石",
    "longitude": "120.40",
    "latitude": "49.17"
  },
  {
    "province": "内蒙",
    "city": "扎兰屯",
    "longitude": "122.47",
    "latitude": "48.00"
  },
  {
    "province": "宁夏",
    "city": "银川",
    "longitude": "106.16",
    "latitude": "38.27"
  },
  {
    "province": "宁夏",
    "city": "青铜峡",
    "longitude": "105.59",
    "latitude": "37.56"
  },
  {
    "province": "宁夏",
    "city": "石嘴山",
    "longitude": "106.22",
    "latitude": "39.02"
  },
  {
    "province": "宁夏",
    "city": "吴忠",
    "longitude": "106.11",
    "latitude": "37.59"
  },
  {
    "province": "青海",
    "city": "西宁",
    "longitude": "101.48",
    "latitude": "36.38"
  },
  {
    "province": "青海",
    "city": "德令哈",
    "longitude": "97.23",
    "latitude": "37.22"
  },
  {
    "province": "青海",
    "city": "格尔木",
    "longitude": "94.55",
    "latitude": "36.26"
  },
  {
    "province": "山东",
    "city": "济南",
    "longitude": "117.00",
    "latitude": "36.40"
  },
  {
    "province": "山东",
    "city": "安丘",
    "longitude": "119.12",
    "latitude": "36.25"
  },
  {
    "province": "山东",
    "city": "滨州",
    "longitude": "118.02",
    "latitude": "37.22"
  },
  {
    "province": "山东",
    "city": "昌邑",
    "longitude": "119.24",
    "latitude": "39.52"
  },
  {
    "province": "山东",
    "city": "德州",
    "longitude": "116.17",
    "latitude": "37.26"
  },
  {
    "province": "山东",
    "city": "东营",
    "longitude": "118.30",
    "latitude": "37.27"
  },
  {
    "province": "山东",
    "city": "肥城",
    "longitude": "116.46",
    "latitude": "36.14"
  },
  {
    "province": "山东",
    "city": "高密",
    "longitude": "119.44",
    "latitude": "36.22"
  },
  {
    "province": "山东",
    "city": "菏泽",
    "longitude": "115.26",
    "latitude": "35.14"
  },
  {
    "province": "山东",
    "city": "胶南",
    "longitude": "119.58",
    "latitude": "35.53"
  },
  {
    "province": "山东",
    "city": "胶州",
    "longitude": "120.00",
    "latitude": "36.17"
  },
  {
    "province": "山东",
    "city": "即墨",
    "longitude": "120.28",
    "latitude": "36.22"
  },
  {
    "province": "山东",
    "city": "济宁",
    "longitude": "116.33",
    "latitude": "35.23"
  },
  {
    "province": "山东",
    "city": "莱芜",
    "longitude": "117.40",
    "latitude": "36.12"
  },
  {
    "province": "山东",
    "city": "莱西",
    "longitude": "120.31",
    "latitude": "36.52"
  },
  {
    "province": "山东",
    "city": "莱阳",
    "longitude": "120.42",
    "latitude": "36.58"
  },
  {
    "province": "山东",
    "city": "莱州",
    "longitude": "119.57",
    "latitude": "37.10"
  },
  {
    "province": "山东",
    "city": "乐陵",
    "longitude": "117.12",
    "latitude": "37.44"
  },
  {
    "province": "山东",
    "city": "聊城",
    "longitude": "115.57",
    "latitude": "36.26"
  },
  {
    "province": "山东",
    "city": "临清",
    "longitude": "115.42",
    "latitude": "36.51"
  },
  {
    "province": "山东",
    "city": "临沂",
    "longitude": "118.20",
    "latitude": "35.03"
  },
  {
    "province": "山东",
    "city": "龙口",
    "longitude": "120.21",
    "latitude": "37.39"
  },
  {
    "province": "山东",
    "city": "蓬莱",
    "longitude": "120.45",
    "latitude": "37.48"
  },
  {
    "province": "山东",
    "city": "平度",
    "longitude": "119.58",
    "latitude": "36.47"
  },
  {
    "province": "山东",
    "city": "青岛",
    "longitude": "120.18",
    "latitude": "36.03"
  },
  {
    "province": "山东",
    "city": "青州",
    "longitude": "118.28",
    "latitude": "36.42"
  },
  {
    "province": "山东",
    "city": "曲阜",
    "longitude": "116.58",
    "latitude": "35.36"
  },
  {
    "province": "山东",
    "city": "日照",
    "longitude": "119.32",
    "latitude": "35.23"
  },
  {
    "province": "山东",
    "city": "荣成",
    "longitude": "122.25",
    "latitude": "37.10"
  },
  {
    "province": "山东",
    "city": "乳山",
    "longitude": "121.31",
    "latitude": "36.54"
  },
  {
    "province": "山东",
    "city": "寿光",
    "longitude": "118.44",
    "latitude": "36.53"
  },
  {
    "province": "山东",
    "city": "泰安",
    "longitude": "117.08",
    "latitude": "36.11"
  },
  {
    "province": "山东",
    "city": "滕州",
    "longitude": "117.09",
    "latitude": "35.06"
  },
  {
    "province": "山东",
    "city": "潍坊",
    "longitude": "119.06",
    "latitude": "36.43"
  },
  {
    "province": "山东",
    "city": "威海",
    "longitude": "122.07",
    "latitude": "37.31"
  },
  {
    "province": "山东",
    "city": "文登",
    "longitude": "122.03",
    "latitude": "37.12"
  },
  {
    "province": "山东",
    "city": "新泰",
    "longitude": "117.45",
    "latitude": "35.54"
  },
  {
    "province": "山东",
    "city": "烟台",
    "longitude": "121.24",
    "latitude": "37.32"
  },
  {
    "province": "山东",
    "city": "兖州",
    "longitude": "116.49",
    "latitude": "35.32"
  },
  {
    "province": "山东",
    "city": "禹城",
    "longitude": "116.39",
    "latitude": "36.56"
  },
  {
    "province": "山东",
    "city": "枣庄",
    "longitude": "117.33",
    "latitude": "34.52"
  },
  {
    "province": "山东",
    "city": "章丘",
    "longitude": "117.32",
    "latitude": "36.43"
  },
  {
    "province": "山东",
    "city": "招远",
    "longitude": "120.23",
    "latitude": "37.21"
  },
  {
    "province": "山东",
    "city": "诸城",
    "longitude": "119.24",
    "latitude": "35.59"
  },
  {
    "province": "山东",
    "city": "淄博",
    "longitude": "118.03",
    "latitude": "36.48"
  },
  {
    "province": "山东",
    "city": "邹城",
    "longitude": "116.58",
    "latitude": "35.24"
  },
  {
    "province": "山西",
    "city": "太原",
    "longitude": "112.33",
    "latitude": "37.54"
  },
  {
    "province": "山西",
    "city": "长治",
    "longitude": "113.06",
    "latitude": "36.11"
  },
  {
    "province": "山西",
    "city": "大同",
    "longitude": "113.17",
    "latitude": "40.06"
  },
  {
    "province": "山西",
    "city": "高平",
    "longitude": "112.55",
    "latitude": "35.48"
  },
  {
    "province": "山西",
    "city": "古交",
    "longitude": "112.09",
    "latitude": "37.54"
  },
  {
    "province": "山西",
    "city": "河津",
    "longitude": "110.41",
    "latitude": "35.35"
  },
  {
    "province": "山西",
    "city": "侯马",
    "longitude": "111.21",
    "latitude": "35.37"
  },
  {
    "province": "山西",
    "city": "霍州",
    "longitude": "111.42",
    "latitude": "36.34"
  },
  {
    "province": "山西",
    "city": "介休",
    "longitude": "111.55",
    "latitude": "37.02"
  },
  {
    "province": "山西",
    "city": "晋城",
    "longitude": "112.51",
    "latitude": "35.30"
  },
  {
    "province": "山西",
    "city": "临汾",
    "longitude": "111.31",
    "latitude": "36.05"
  },
  {
    "province": "山西",
    "city": "潞城",
    "longitude": "113.14",
    "latitude": "36.21"
  },
  {
    "province": "山西",
    "city": "朔州",
    "longitude": "112.26",
    "latitude": "39.19"
  },
  {
    "province": "山西",
    "city": "孝义",
    "longitude": "111.48",
    "latitude": "37.08"
  },
  {
    "province": "山西",
    "city": "忻州",
    "longitude": "112.43",
    "latitude": "38.24"
  },
  {
    "province": "山西",
    "city": "阳泉",
    "longitude": "113.34",
    "latitude": "37.51"
  },
  {
    "province": "山西",
    "city": "永济",
    "longitude": "110.27",
    "latitude": "34.52"
  },
  {
    "province": "山西",
    "city": "原平",
    "longitude": "112.42",
    "latitude": "38.43"
  },
  {
    "province": "山西",
    "city": "榆次",
    "longitude": "112.43",
    "latitude": "37.41"
  },
  {
    "province": "山西",
    "city": "运城",
    "longitude": "110.59",
    "latitude": "35.02"
  },
  {
    "province": "陕西",
    "city": "西安",
    "longitude": "108.57",
    "latitude": "34.17"
  },
  {
    "province": "陕西",
    "city": "安康",
    "longitude": "109.01",
    "latitude": "32.41"
  },
  {
    "province": "陕西",
    "city": "宝鸡",
    "longitude": "107.09",
    "latitude": "34.22"
  },
  {
    "province": "陕西",
    "city": "韩城",
    "longitude": "110.27",
    "latitude": "35.28"
  },
  {
    "province": "陕西",
    "city": "汉中",
    "longitude": "107.01",
    "latitude": "33.04"
  },
  {
    "province": "陕西",
    "city": "华阴",
    "longitude": "110.05",
    "latitude": "34.34"
  },
  {
    "province": "陕西",
    "city": "商州",
    "longitude": "109.57",
    "latitude": "33.52"
  },
  {
    "province": "陕西",
    "city": "铜川",
    "longitude": "109.07",
    "latitude": "35.06"
  },
  {
    "province": "陕西",
    "city": "渭南",
    "longitude": "109.30",
    "latitude": "34.30"
  },
  {
    "province": "陕西",
    "city": "咸阳",
    "longitude": "108.43",
    "latitude": "34.20"
  },
  {
    "province": "陕西",
    "city": "兴平",
    "longitude": "108.29",
    "latitude": "34.18"
  },
  {
    "province": "陕西",
    "city": "延安",
    "longitude": "109.28",
    "latitude": "36.35"
  },
  {
    "province": "陕西",
    "city": "榆林",
    "longitude": "109.47",
    "latitude": "38.18"
  },
  {
    "province": "上海",
    "city": "上海",
    "longitude": "121.29",
    "latitude": "31.14"
  },
  {
    "province": "四川",
    "city": "成都",
    "longitude": "104.04",
    "latitude": "30.40"
  },
  {
    "province": "四川",
    "city": "巴中",
    "longitude": "106.43",
    "latitude": "31.51"
  },
  {
    "province": "四川",
    "city": "崇州",
    "longitude": "103.40",
    "latitude": "30.39"
  },
  {
    "province": "四川",
    "city": "达川",
    "longitude": "107.29",
    "latitude": "31.14"
  },
  {
    "province": "四川",
    "city": "德阳",
    "longitude": "104.22",
    "latitude": "31.09"
  },
  {
    "province": "四川",
    "city": "都江堰",
    "longitude": "103.37",
    "latitude": "31.01"
  },
  {
    "province": "四川",
    "city": "峨眉山",
    "longitude": "103.29",
    "latitude": "29.36"
  },
  {
    "province": "四川",
    "city": "涪陵",
    "longitude": "107.22",
    "latitude": "29.42"
  },
  {
    "province": "四川",
    "city": "广汉",
    "longitude": "104.15",
    "latitude": "30.58"
  },
  {
    "province": "四川",
    "city": "广元",
    "longitude": "105.51",
    "latitude": "32.28"
  },
  {
    "province": "四川",
    "city": "华蓥",
    "longitude": "106.44",
    "latitude": "30.26"
  },
  {
    "province": "四川",
    "city": "简阳",
    "longitude": "104.32",
    "latitude": "30.24"
  },
  {
    "province": "四川",
    "city": "江油",
    "longitude": "104.42",
    "latitude": "31.48"
  },
  {
    "province": "四川",
    "city": "阆中",
    "longitude": "105.58",
    "latitude": "31.36"
  },
  {
    "province": "四川",
    "city": "乐山",
    "longitude": "103.44",
    "latitude": "29.36"
  },
  {
    "province": "四川",
    "city": "泸州",
    "longitude": "105.24",
    "latitude": "28.54"
  },
  {
    "province": "四川",
    "city": "绵阳",
    "longitude": "104.42",
    "latitude": "31.30"
  },
  {
    "province": "四川",
    "city": "南充",
    "longitude": "106.04",
    "latitude": "30.49"
  },
  {
    "province": "四川",
    "city": "内江",
    "longitude": "105.02",
    "latitude": "29.36"
  },
  {
    "province": "四川",
    "city": "攀枝花",
    "longitude": "101.43",
    "latitude": "26.34"
  },
  {
    "province": "四川",
    "city": "彭州",
    "longitude": "103.57",
    "latitude": "30.59"
  },
  {
    "province": "四川",
    "city": "邛崃",
    "longitude": "103.28",
    "latitude": "30.26"
  },
  {
    "province": "四川",
    "city": "遂宁",
    "longitude": "105.33",
    "latitude": "30.31"
  },
  {
    "province": "四川",
    "city": "万县",
    "longitude": "108.21",
    "latitude": "30.50"
  },
  {
    "province": "四川",
    "city": "万源",
    "longitude": "108.03",
    "latitude": "32.03"
  },
  {
    "province": "四川",
    "city": "西昌",
    "longitude": "102.16",
    "latitude": "27.54"
  },
  {
    "province": "四川",
    "city": "雅安",
    "longitude": "102.59",
    "latitude": "29.59"
  },
  {
    "province": "四川",
    "city": "宜宾",
    "longitude": "104.34",
    "latitude": "28.47"
  },
  {
    "province": "四川",
    "city": "自贡",
    "longitude": "104.46",
    "latitude": "29.23"
  },
  {
    "province": "四川",
    "city": "资阳",
    "longitude": "104.38",
    "latitude": "30.09"
  },
  {
    "province": "台湾",
    "city": "台北市",
    "longitude": "121.30",
    "latitude": "25.03"
  },
  {
    "province": "天津",
    "city": "天津",
    "longitude": "117.12",
    "latitude": "39.02"
  },
  {
    "province": "西藏自治区",
    "city": "拉萨",
    "longitude": "91.08",
    "latitude": "29.39"
  },
  {
    "province": "西藏自治区",
    "city": "日喀则",
    "longitude": "88.51",
    "latitude": "29.16"
  },
  {
    "province": "香港",
    "city": "香港",
    "longitude": "114.171168",
    "latitude": "22.283198"
  },
  {
    "province": "新疆自治区",
    "city": "乌鲁木齐",
    "longitude": "87.36",
    "latitude": "43.45"
  },
  {
    "province": "新疆自治区",
    "city": "阿克苏",
    "longitude": "80.19",
    "latitude": "41.09"
  },
  {
    "province": "新疆自治区",
    "city": "阿勒泰",
    "longitude": "88.12",
    "latitude": "47.50"
  },
  {
    "province": "新疆自治区",
    "city": "阿图什",
    "longitude": "76.08",
    "latitude": "39.42"
  },
  {
    "province": "新疆自治区",
    "city": "博乐",
    "longitude": "82.08",
    "latitude": "44.57"
  },
  {
    "province": "新疆自治区",
    "city": "昌吉",
    "longitude": "87.18",
    "latitude": "44.02"
  },
  {
    "province": "新疆自治区",
    "city": "阜康",
    "longitude": "87.58",
    "latitude": "44.09"
  },
  {
    "province": "新疆自治区",
    "city": "哈密",
    "longitude": "93.28",
    "latitude": "42.50"
  },
  {
    "province": "新疆自治区",
    "city": "和田",
    "longitude": "79.55",
    "latitude": "37.09"
  },
  {
    "province": "新疆自治区",
    "city": "克拉玛依",
    "longitude": "84.51",
    "latitude": "45.36"
  },
  {
    "province": "新疆自治区",
    "city": "喀什",
    "longitude": "75.59",
    "latitude": "39.30"
  },
  {
    "province": "新疆自治区",
    "city": "库尔勒",
    "longitude": "86.07",
    "latitude": "41.46"
  },
  {
    "province": "新疆自治区",
    "city": "奎屯",
    "longitude": "84.56",
    "latitude": "44.27"
  },
  {
    "province": "新疆自治区",
    "city": "石河子",
    "longitude": "86.00",
    "latitude": "44.18"
  },
  {
    "province": "新疆自治区",
    "city": "塔城",
    "longitude": "82.59",
    "latitude": "46.46"
  },
  {
    "province": "新疆自治区",
    "city": "吐鲁番",
    "longitude": "89.11",
    "latitude": "42.54"
  },
  {
    "province": "新疆自治区",
    "city": "伊宁",
    "longitude": "81.20",
    "latitude": "43.55"
  },
  {
    "province": "云南",
    "city": "昆明",
    "longitude": "102.42",
    "latitude": "25.04"
  },
  {
    "province": "云南",
    "city": "保山",
    "longitude": "99.10",
    "latitude": "25.08"
  },
  {
    "province": "云南",
    "city": "楚雄",
    "longitude": "101.32",
    "latitude": "25.01"
  },
  {
    "province": "云南",
    "city": "大理",
    "longitude": "100.13",
    "latitude": "25.34"
  },
  {
    "province": "云南",
    "city": "东川",
    "longitude": "103.12",
    "latitude": "26.06"
  },
  {
    "province": "云南",
    "city": "个旧",
    "longitude": "103.09",
    "latitude": "23.21"
  },
  {
    "province": "云南",
    "city": "景洪",
    "longitude": "100.48",
    "latitude": "22.01"
  },
  {
    "province": "云南",
    "city": "开远",
    "longitude": "103.13",
    "latitude": "23.43"
  },
  {
    "province": "云南",
    "city": "曲靖",
    "longitude": "103.48",
    "latitude": "25.30"
  },
  {
    "province": "云南",
    "city": "瑞丽",
    "longitude": "97.50",
    "latitude": "24.00"
  },
  {
    "province": "云南",
    "city": "思茅",
    "longitude": "100.58",
    "latitude": "22.48"
  },
  {
    "province": "云南",
    "city": "畹町",
    "longitude": "98.04",
    "latitude": "24.06"
  },
  {
    "province": "云南",
    "city": "宣威",
    "longitude": "104.06",
    "latitude": "26.13"
  },
  {
    "province": "云南",
    "city": "玉溪",
    "longitude": "102.32",
    "latitude": "24.22"
  },
  {
    "province": "云南",
    "city": "昭通",
    "longitude": "103.42",
    "latitude": "27.20"
  },
  {
    "province": "浙江",
    "city": "杭州",
    "longitude": "120.10",
    "latitude": "30.16"
  },
  {
    "province": "浙江",
    "city": "慈溪",
    "longitude": "121.15",
    "latitude": "30.11"
  },
  {
    "province": "浙江",
    "city": "东阳",
    "longitude": "120.14",
    "latitude": "29.16"
  },
  {
    "province": "浙江",
    "city": "奉化",
    "longitude": "121.24",
    "latitude": "29.39"
  },
  {
    "province": "浙江",
    "city": "富阳",
    "longitude": "119.57",
    "latitude": "30.03"
  },
  {
    "province": "浙江",
    "city": "海宁",
    "longitude": "120.42",
    "latitude": "30.32"
  },
  {
    "province": "浙江",
    "city": "湖州",
    "longitude": "120.06",
    "latitude": "30.52"
  },
  {
    "province": "浙江",
    "city": "建德",
    "longitude": "119.16",
    "latitude": "29.29"
  },
  {
    "province": "浙江",
    "city": "江山",
    "longitude": "118.37",
    "latitude": "28.45"
  },
  {
    "province": "浙江",
    "city": "嘉兴",
    "longitude": "120.45",
    "latitude": "30.46"
  },
  {
    "province": "浙江",
    "city": "金华",
    "longitude": "119.39",
    "latitude": "29.07"
  },
  {
    "province": "浙江",
    "city": "兰溪",
    "longitude": "119.28",
    "latitude": "29.12"
  },
  {
    "province": "浙江",
    "city": "临海",
    "longitude": "121.08",
    "latitude": "28.51"
  },
  {
    "province": "浙江",
    "city": "丽水",
    "longitude": "119.54",
    "latitude": "28.27"
  },
  {
    "province": "浙江",
    "city": "龙泉",
    "longitude": "119.08",
    "latitude": "28.04"
  },
  {
    "province": "浙江",
    "city": "宁波",
    "longitude": "121.33",
    "latitude": "29.52"
  },
  {
    "province": "浙江",
    "city": "平湖",
    "longitude": "121.01",
    "latitude": "30.42"
  },
  {
    "province": "浙江",
    "city": "衢州",
    "longitude": "118.52",
    "latitude": "28.58"
  },
  {
    "province": "浙江",
    "city": "瑞安",
    "longitude": "120.38",
    "latitude": "27.48"
  },
  {
    "province": "浙江",
    "city": "上虞",
    "longitude": "120.52",
    "latitude": "30.01"
  },
  {
    "province": "浙江",
    "city": "绍兴",
    "longitude": "120.34",
    "latitude": "30.00"
  },
  {
    "province": "浙江",
    "city": "台州",
    "longitude": "121.27",
    "latitude": "28.41"
  },
  {
    "province": "浙江",
    "city": "桐乡",
    "longitude": "120.32",
    "latitude": "30.38"
  },
  {
    "province": "浙江",
    "city": "温岭",
    "longitude": "121.21",
    "latitude": "28.22"
  },
  {
    "province": "浙江",
    "city": "温州",
    "longitude": "120.39",
    "latitude": "28.01"
  },
  {
    "province": "浙江",
    "city": "萧山",
    "longitude": "120.16",
    "latitude": "30.09"
  },
  {
    "province": "浙江",
    "city": "义乌",
    "longitude": "120.04",
    "latitude": "29.18"
  },
  {
    "province": "浙江",
    "city": "乐清",
    "longitude": "120.58",
    "latitude": "28.08"
  },
  {
    "province": "浙江",
    "city": "余杭",
    "longitude": "120.18",
    "latitude": "30.26"
  },
  {
    "province": "浙江",
    "city": "余姚",
    "longitude": "121.10",
    "latitude": "30.02"
  },
  {
    "province": "浙江",
    "city": "永康",
    "longitude": "120.01",
    "latitude": "29.54"
  },
  {
    "province": "浙江",
    "city": "舟山",
    "longitude": "122.06",
    "latitude": "30.01"
  },
  {
    "province": "浙江",
    "city": "诸暨",
    "longitude": "120.14",
    "latitude": "29.43"
  },
  {
    "province": "重庆",
    "city": "重庆",
    "longitude": "106.33",
    "latitude": "29.35"
  },
  {
    "province": "重庆",
    "city": "合川市",
    "longitude": "106.15",
    "latitude": "30.02"
  },
  {
    "province": "重庆",
    "city": "江津市",
    "longitude": "106.16",
    "latitude": "29.18"
  },
  {
    "province": "重庆",
    "city": "南川市",
    "longitude": "107.05",
    "latitude": "29.10"
  },
  {
    "province": "重庆",
    "city": "永川市",
    "longitude": "105.53",
    "latitude": "29.23"
  },
  {
    "province": "江西",
    "city": "抚州",
    "longitude": "116.359506",
    "latitude": "27.960509"
  }
]
citys.forEach(c=>{
  countryTreeOptions[2].children[0].children.forEach(country=>{
    if(c.province === country.label){
      if( country.children ){
        country.children.push({
          label: c.city,
          value: c.city,
          location: [c.longitude,c.latitude]
        })
      }else{
        country.location = [c.longitude,c.latitude]
      }
    }
  })
})
Vue.prototype.countryTreeOptions = countryTreeOptions
