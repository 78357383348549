// 常量
export const assessment = {
  // pia
  1: {
    assessmentType: 1,
    assessCode: 'P01',
  },
  // 认证评估
  2: {
    assessmentType: 2,
    assessCode: 'P03',
  },
  // 快速评估
  3: {
    assessmentType: 3,
    assessCode: 'P02',
  },
  // 个人信息共享评估
  4: {
    assessmentType: 4,
    assessCode: 'P05',
  },
  // 第三方合规评估
  5: {
    assessmentType: 5,
    assessCode: 'P04',
  },
}
