import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

import statement from '@/router/routes/statement'
import privacyFramework from './routes/privacyFramework'
import dataIntelligent from './routes/dataIntelligent'
import assessment from './routes/assessment'
import risk from './routes/risk'
import cpm from './routes/cpm'
import cookies from './routes/cookies'
import thirdPartCompliance from './routes/thirdPartCompliance'
import emergency from './routes/emergency'
import privacyDesign from './routes/privacyDesign'
import system from './routes/system'
import enterprise from './routes/enterprise'
import pages from './routes/pages'
import project from './routes/project'
import flow from './routes/flow'
import custom from './routes/custom'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...privacyFramework,
    ...dataIntelligent,
    ...assessment,
    ...risk,
    ...cpm,
    ...cookies,
    ...thirdPartCompliance,
    ...emergency,
    ...privacyDesign,
    ...enterprise,
    ...system,
    ...pages,
    ...statement,
    ...project,
    ...flow,
    ...custom,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.name === 'compliance-report' || to.name === '/pia-report') {
    document.getElementsByTagName('body')[0].className = 'report-body'
  }
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })
  //
  //   // If logged in => not authorized
  //   return next({ name: 'not-authorized' })
  // }
  if (to.name !== 'login' && to.name !== 'auth-login' && to.name !== 'register' && to.name !== 'init' && to.name !== 'privacyStatement' && to.name !== 'agree') {
    if (!isLoggedIn) return next({ name: 'auth-login', query: to.meta.query })
  }
  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
