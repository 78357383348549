export default [
  {
    path: '/settings/log',
    name: 'log',
    component: () => import('@/views/system/log/LogList.vue'),
    meta: {
      resource: 'log',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '日志管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/notify',
    name: 'notify',
    component: () => import('@/views/system/notify/Notify.vue'),
    meta: {
      resource: 'notify',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '通知管理',
          active: true,
        },
      ],
    },
  },



  {
    path: '/settings/resource',
    name: 'resource',
    component: () => import('@/views/system/resource/Resource.vue'),
    meta: {
      resource: 'resource',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '资源管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/smtp',
    name: 'smtp',
    component: () => import('@/views/system/smtp/SMTPSetting.vue'),
    meta: {
      resource: 'email',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '短信配置',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/auth',
    name: 'auth',
    component: () => import('@/views/system/auth/Auth.vue'),
    meta: {
      resource: 'notify',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '认证管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/authorInfo',
    name: 'authorInfo',
    component: () => import('@/views/system/authorInfo/AuthorInfo.vue'),
    meta: {
      resource: 'notify',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '认证管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/config',
    name: 'config',
    component: () => import('@/views/system/config/Config.vue'),
    meta: {
      resource: 'notify',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '系统配置',
          active: true,
        },
      ],
    },
  },

]
