import { assessment } from '@/@core/utils/constant'
export default [
  {
    path: '/thirdPartInfo',
    name: 'thirdPartInfo',
    component: () => import('@/views/thirdPartCompliance/thirdPartInfo/ThirdPartInfo.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方信息管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dataShare',
    name: 'dataShare',
    component: () => import('@/views/thirdPartCompliance/dataShare/DataShare.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方信息管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dataShareSave',
    name: 'dataShareSave',
    component: () => import('@/views/thirdPartCompliance/dataShare/DataShareSave.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '数据共享管理',
          to: '/dataShare',
        },
        {
          text: '数据共享添加',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('@/views/thirdPartCompliance/cooperation/Cooperation.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '合作协议管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cooperation-save',
    name: 'cooperation-save',
    component: () => import('@/views/thirdPartCompliance/cooperation/CooperationSave.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '合作协议管理',
          to: '/cooperation',
        },
        {
          text: '合作协议添加',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sharedAssessment',
    name: 'sharedAssessment',
    component: () => import('@/views/assessment/AssessmentList.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      assessmentType: assessment[4].assessmentType,
      assessCode: assessment[4].assessCode,
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '个人信息共享评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shareAssementSave',
    name: 'shareAssementSave',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '个人信息共享评估',
          to: '/sharedAssessment',
        },
        {
          text: '创建个人信息共享评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shareAssementEditor',
    name: 'shareAssementEditor',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '个人信息共享评估',
          to: '/sharedAssessment',
        },
        {
          text: '编辑个人信息共享评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/share-answer',
    name: 'share-answer',
    component: () => import('@/views/assessment/AssessmentAnswe.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '个人信息共享评估',
          to: '/sharedAssessment',
        },
        {
          text: '个人信息共享评估填写',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thirdPartCompliance',
    name: 'thirdPartCompliance',
    component: () => import('@/views/assessment/AssessmentList.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      assessmentType: assessment[5].assessmentType,
      assessCode: assessment[5].assessCode,
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '第三方合规评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thirdAssementSave',
    name: 'thirdAssementSave',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '第三方合规评估',
          to: '/thirdPartCompliance',
        },
        {
          text: '创建第三方合规',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thirdAssementEditor',
    name: 'thirdAssementEditor',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '第三方合规评估',
          to: '/thirdPartCompliance',
        },
        {
          text: '编辑第三方合规',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thirdPart-answer',
    name: 'thirdPart-answer',
    component: () => import('@/views/assessment/AssessmentAnswe.vue'),
    meta: {
      pageTitle: '第三方合规管理',
      breadcrumb: [
        {
          text: '第三方合规管理',
        },
        {
          text: '第三方合规评估',
          to: '/thirdPartCompliance',
        },
        {
          text: '第三方合规审核',
          active: true,
        },
      ],
    },
  },
]
