<template>
  <span
    class="badge d-flex justify-content-center badge-pill"
    style="align-items: center;"
    :style="{background: color}"
  >
    {{ label }}
  </span>
</template>
<script>
export default {
  props: {
    label: String,
    color: String,
  },
  data() {
    return {}
  },
  created() {
  },
}
</script>
<style lang="scss">

</style>
