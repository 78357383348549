import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import appOptions from './app-options'
import verticalMenu from './vertical-menu'
import pageQuery from './pageQuery'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    appOptions,
    verticalMenu,
    pageQuery,
  },
  strict: process.env.DEV,
})
