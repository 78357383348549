import { assessment } from '@/@core/utils/constant'

export default [
  {
    path: '/compliance-list',
    name: 'compliance-list',
    component: () => import('@/views/assessment/AssessmentList.vue'),
    meta: {
      resource: 'compliance',
      assessmentType: assessment[3].assessmentType,
      assessCode: assessment[3].assessCode,
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '快速评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/compliance-save',
    name: 'compliance-save',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '快速评估',
          to: { path: '/compliance-list' },
        },
        {
          text: '创建评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/compliance-analysis',
    name: 'compliance-analysis',
    component: () => import('@/views/assessment/AssessmentList.vue'),
    meta: {
      resource: 'compliance',
      assessmentType: assessment[2].assessmentType,
      assessCode: assessment[2].assessCode,
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '认证评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gap-answer',
    name: 'gap-answer',
    component: () => import('@/views/assessment/AssessmentAnswe.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '认证评估',
          to: { path: '/compliance-analysis' },
        },
        {
          text: '认证评估填写',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gap-save',
    name: 'gap-save',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '认证评估',
          to: { path: '/compliance-analysis' },
        },
        {
          text: '创建评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gap-editor',
    name: 'gap-editor',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '认证评估',
          to: { path: '/compliance-analysis' },
        },
        {
          text: '编辑评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/compliance-save',
    name: 'compliance-save',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '快速评估',
          to: { path: '/compliance-list' },
        },
        {
          text: '创建快速评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/compliance-answer',
    name: 'compliance-answer',
    component: () => import('@/views/assessment/AssessmentAnswe.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: '快速评估',
          to: { path: '/compliance-list' },
        },
        {
          text: '填写快速评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pia',
    name: 'pia',
    component: () => import('@/views/assessment/AssessmentList.vue'),
    meta: {
      pageTitle: '合规评估',
      assessmentType: assessment[1].assessmentType,
      assessCode: assessment[1].assessCode,
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: 'PIA评估',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pia-answer',
    name: 'pia-answer',
    component: () => import('@/views/assessment/AssessmentAnswe.vue'),
    meta: {
      pageTitle: 'PIA评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: 'PIA评估',
          to: { path: '/pia' },
        },
        {
          text: 'PIA填写',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pia-save',
    name: 'pia-save',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      pageTitle: 'PIA评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: 'PIA评估',
          to: { path: '/pia' },
        },
        {
          text: '创建PIA',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pia-editor',
    name: 'pia-editor',
    component: () => import('@/views/assessment/AssessmentSave.vue'),
    meta: {
      pageTitle: 'PIA评估',
      breadcrumb: [
        {
          text: '合规评估',
        },
        {
          text: 'PIA评估',
          to: { path: '/pia' },
        },
        {
          text: '修改PIA',
          active: true,
        },
      ],
    },
  },
]
