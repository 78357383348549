import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import SearchFilter from '@core/components/search-filter/SearchFilter.vue'
import PageQuery from '@core/components/pageQuery/PageQuery.vue'
import BtnPerms from '@core/components/btn-perms/BtnPerms.vue'
import StatusBadge from '@/@core/components/statusBadge/StatusBadge.vue'
Vue.component('SearchFilter', SearchFilter)
Vue.component('PageQuery', PageQuery)
Vue.component('BtnPerms', BtnPerms)
Vue.component('StatusBadge', StatusBadge)
Vue.component(FeatherIcon.name, FeatherIcon)
