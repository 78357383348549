export default [
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/views/enterprise/enterprise/Enterprise.vue'),
    meta: {
      resource: 'enterprise',
      action: 'manage',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '企业信息管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/enterprise/product/Product.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '业务管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-save',
    name: 'product-save',
    component: () => import('@/views/enterprise/product/ProductSave.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '业务管理',
          to: '/product',
        },
        {
          text: '业务编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-detail',
    name: 'product-detail',
    component: () => import('@/views/enterprise/product/ProductDetail.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '业务管理',
          to: '/product',
        },
        {
          text: '业务详情',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-scene',
    name: 'product-scene',
    component: () => import('@/views/enterprise/product/ProductScene.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '业务管理',
          to: '/product',
        },
        {
          text: '业务场景',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sceneSave',
    name: 'sceneSave',
    component: () => import('@/views/enterprise/product/SceneSave.vue'),
    meta: {
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '业务管理',
          to: '/product',
        },
        {
          text: '业务场景',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/user',
    name: 'user',
    component: () => import('@/views/enterprise/user/User.vue'),
    meta: {
      resource: 'user',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '用户管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/organization',
    name: 'organization',
    component: () => import('@/views/enterprise/dept/Organization.vue'),
    meta: {
      resource: 'organization',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '部门管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-edit',
    name: 'user-edit',
    component: () => import('@/views/enterprise/user/UserEdit.vue'),
    meta: {
      resource: 'user',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '用户管理',
          to: {name:'user'},
        },
        {
          text: '用户编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/role',
    name: 'role',
    component: () => import('@/views/enterprise/role/Role.vue'),
    meta: {
      resource: 'role',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '角色管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/role-edit',
    name: 'role-edit',
    component: () => import('@/views/enterprise/role/RoleEdit.vue'),
    meta: {
      resource: 'role',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '角色管理',
          to: {name:'role'},
        },
        {
          text: '角色编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thridParty',
    name: 'thridParty',
    component: () => import('@/views/enterprise/thirdPParty/ThridParty'),
    meta: {
      resource: 'datamap',
      pageTitle: '企业管理',
      breadcrumb: [
        {
          text: '第三方管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('@/views/enterprise/thirdPParty/Cooperation'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据管理',
      breadcrumb: [
        {
          text: '第三方管理',
          to: '/thridParty',
        },
        {
          text: '合作情况',
          active: true,
        },
      ],
    },
  },
]
