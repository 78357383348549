export default [
  {
    path: '/risk-stat',
    name: 'risk-stat',
    component: () => import('@/views/risk/riskState/RiskState.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [{
        text: '风险态势',
        active: true,
      }],
    },
  },
  {
    path: '/risk-perception',
    name: 'risk-perception',
    component: () => import('@/views/risk/riskPerception/RiskPerception.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [{
        text: '风险感知',
        active: true,
      }],
    },
  },
  {
    path: '/risk-rectify',
    name: 'risk-rectify',
    component: () => import('@/views/risk/riskRectify/RiskRectify.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [{
        text: '风险整改',
        active: true,
      }],
    },
  },
  {
    path: '/risk-history',
    name: 'risk-history',
    component: () => import('@/views/risk/riskHistory/RiskHistory.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [{
        text: '风险历史',
        active: true,
      }],
    },
  },
  {
    path: '/risk-save',
    name: 'risk-save',
    component: () => import('@/views/risk/riskPerception/RiskSave.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [
        {
          text: '风险感知',
          to: { path: '/risk-perception' },
        },
        {
          text: '风险录入',
          active: true,
        },
      ],
    },
  },
  {
    path: '/risk-perceptiondetail',
    name: 'risk-detail',
    component: () => import('@/views/risk/RiskDetail.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [
        {
          text: '风险感知',
          to: { path: '/risk-perception' },
        },
        {
          text: '风险详情',
          active: true,
        },
      ],
    },
  },
  {
    path: '/risk-rectifydetail',
    name: 'risk-detail',
    component: () => import('@/views/risk/RiskDetail.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [
        {
          text: '风险整改',
          to: { path: '/risk-rectify' },
        },
        {
          text: '风险详情',
          active: true,
        },
      ],
    },
  },
  {
    path: '/risk-histroydetail',
    name: 'risk-detail',
    component: () => import('@/views/risk/RiskDetail.vue'),
    meta: {
      pageTitle: '风险管理',
      breadcrumb: [
        {
          text: '风险历史',
          to: { path: '/risk-history' },
        },
        {
          text: '风险详情',
          active: true,
        },
      ],
    },
  },
]
