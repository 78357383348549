export default [
  {
    path: '/custom/list',
    name: 'customRequest',
    component: () => import('@/views/custom/right-request.vue'),
    meta: {
      pageTitle: '响应列表',
      breadcrumb: [
        {
          text: '响应列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom/request/edit',
    name: 'customRequestEdit',
    component: () => import('@/views/custom/right-request-edit.vue'),
    meta: {
      pageTitle: '响应列表',
      breadcrumb: [
        {
          text: '响应列表',
          to: '/custom/list',
        },
        {
          text: '编辑响应请求',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom/request/detail',
    name: 'customRequestDetail',
    component: () => import('@/views/custom/right-request-detail.vue'),
    meta: {
      pageTitle: '响应列表',
      breadcrumb: [
        {
          text: '响应列表',
          to: '/custom/list',
        },
        {
          text: '编辑响应详情',
          active: true,
        },
      ],
    },
  },
]
