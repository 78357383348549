export default [
  {
    path: '/policy/organization',
    name: 'policy-organization',
    component: () => import('@/views/privacyFramework/organization/Organization.vue'),
    meta: {
      pageTitle: '隐私框架',
      breadcrumb: [
        {
          text: '隐私组织',
          active: true,
        },
      ],
    },
  },
  {
    path: '/privacy-read',
    name: 'privacy-read',
    component: () => import('@/views/privacyFramework/organization/PrivacyRead.vue'),
    meta: {
      pageTitle: '隐私框架',
      breadcrumb: [
        {
          text: '隐私组织',
          to: { path: '/policy-organization' },
        },
        {
          text: '隐私组织预览',
          active: true,
        },
      ],
    },
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/privacyFramework/policy/Policy.vue'),
    meta: {
      action: 'manage',
      pageTitle: '隐私框架',
      breadcrumb: [
        {
          text: '隐私政策',
          active: true,
        },
      ],
    },
  },
  {
    path: '/policy-read',
    name: 'policy-read',
    component: () => import('@/views/privacyFramework/policy/PolicyRead.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/PolicyEdit',
    name: 'PolicyEdit',
    component: () => import('@/views/privacyFramework/policy/PolicyEditor.vue'),
    meta: {
      action: 'manage',
      pageTitle: '隐私框架',
      breadcrumb: [
        {
          text: '隐私政策',
          to: '/policy',
        },
        {
          text: '新建政策',
          active: true,
        },
      ],
    },
  },
  {
    path: '/regime',
    name: 'regime',
    component: () => import('@/views/privacyFramework/regime/Regime.vue'),
    meta: {
      action: 'manage',
      pageTitle: '隐私框架',
      breadcrumb: [{
        text: '制度流程',
        active: true,
      }],
    },
  },
  {
    path: '/regime-save',
    name: 'regime-save',
    component: () => import('@/views/privacyFramework/regime/RegimeSave.vue'),
    meta: {
      action: 'manage',
      pageTitle: '制度流程',
      breadcrumb: [
        {
          text: '制度流程',
          to: '/regime',
        },
        {
          text: '制度填写',
          active: true,
        },
      ],
    },
  },
  {
    path: '/regime-read',
    name: 'regime-read',
    component: () => import('@/views/privacyFramework/regime/RegimeRead.vue'),
    meta: {
      layout: 'full',
    },
  },
]
