export default [{
  path: '/',
  name: 'home',
  component: () => import('@/views/workbench/Workbench.vue'),
  meta: {
    pageTitle: 'Home',
    resource: 'home',
    action: 'read',
    breadcrumb: [{
      text: 'Home',
      active: true,
    }],
  },
}, {
  path: '/quick-start',
  name: 'quick-start',
  component: () => import('@/views/QuickStart.vue'),
  meta: {
    pageTitle: 'Quick Start',
    resource: 'quickStart',
    action: 'read',
    breadcrumb: [{
      text: 'Home',
      active: true,
    }],
  },
}, {
  path: '/workbench',
  name: 'workbench',
  component: () => import('@/views/workbench/Workbench.vue'),
  meta: {
    pageTitle: '工作台',
    breadcrumb: [{
      text: '工作台',
      active: true,
    }],
  },
},
//   {
//   path: '/workbenchdemo',
//   name: 'workbenchdemo',
//   component: () => import('@/views/workbench/demo/Workbench.vue'),
//   meta: {
//     pageTitle: '工作台Demo',
//     breadcrumb: [{
//       text: '工作台Demo',
//       active: true,
//     }],
//   },
// },
{
  path: '/docment',
  name: 'docment',
  component: () => import('@/views/docment/docment.vue'),
  meta: {
    pageTitle: '文档管理',
    breadcrumb: [{
      text: '文档管理',
      active: true,
    }],
  },
}, {
  path: '/profile',
  name: 'profile',
  component: () => import('@/views/profile/AccountSetting.vue'),
  meta: {
    pageTitle: '账号设置',
    breadcrumb: [{
      text: '个人信息',
      active: true,
    }],
    resource: 'profile',
    action: 'manage',
  },
}, {
  path: '/login',
  name: 'auth-login',
  component: () => import('@/views/Login.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
}, {
  path: '/Register',
  name: 'register',
  component: () => import('@/views/Register.vue'),
  meta: {
    layout: 'full',
  },
}, {
  path: '/not-authorized',
  name: 'not-authorized',
  component: () => import('@/views/error/NotAuthorized.vue'),
  meta: {
    layout: 'full',
    action: 'read',
    resource: 'Auth',
  },
},
{
  path: '/init',
  name: 'init',
  component: () => import('@/views/pageInit/Init.vue'),
  meta: {
    layout: 'full',
    action: 'read',
  },
},
{
  path: '/error-404',
  name: 'error-404',
  component: () => import('@/views/error/Error404.vue'),
  meta: {
    layout: 'full',
  },
}, {
  path: '*',
  redirect: 'error-404',
},
{
  path: '/policyFile',
  name: 'policyFile',
  component: () => import('@/views/privacyFramework/policy/PolicyFile.vue'),
  meta: {
    layout: 'full',
    active: true,
  },
}, {
  path: '/statement-read',
  name: '/statement-read',
  component: () => import('@/views/statement/StatementRead.vue'),
  meta: {
    layout: 'full',
    active: true,
  },
},
{
  path: '/cooperation-read',
  name: '/cooperation-read',
  component: () => import('@/views/thirdPartCompliance/cooperation/CooperationRead.vue'),
  meta: {
    layout: 'full',
    active: true,
  },
},
{
  path: '/ixit-privew',
  name: 'ixit-privew',
  component: () => import('@/views/dataIntelligent/business/IxitPrivew.vue'),
  meta: {
    layout: 'full',
    active: true,
  },
},
  {
    path: '/privacyStatement',
    name: 'privacyStatement',
    component: () => import('@/views/provisions/privacyStatement.vue'),
    meta: {
      layout: 'full',
      action: 'read',
    },
  },
  {
    path: '/agree',
    name: 'agree',
    component: () => import('@/views/provisions/agree.vue'),
    meta: {
      layout: 'full',
      action: 'read',
    },
  },
]
