import { get, post } from '@/libs/axios'

// eslint-disable-next-line import/prefer-default-export
export const UploadFile = params => post('/system/settings/UploadFile', params)
export const AccessTempFileByPath = params => get('/system/AccessTempFileByPath', params)
export const CommitLicenseCode = params => post('/system/settings/CommitLicenseCode', params)
export const QueryDeptUsers = params => post('/system/user/QueryDeptUsers', params)
export const GetUserRoleResourceInfo = params => post('/system/user/GetUserRoleResourceInfo', params)
export const queryDeptOptions = () => get('/system/dept/select')
export const queryUserByDept = deptId => get(`/system/user/QueryUserByDept?deptId=${deptId}`)
export const queryUserOptions = () => get('/system/user/QueryUserOptions')
export const FilterUserOptions = (deptId, roleId) => get(`/system/user/FilterUserOptions?deptId=${deptId}&roleId=${roleId}`)
export const GetMachineCode = () => get('/system/settings/GetMachineCode')
export const MenuNav = () => get('/sys/menu/nav')

// 查询当前企业短信设置
export const GetSmsSettings = () => get('/system/sms/GetSmsSettings')
export const GetInitFirstDefaultRole = () => get('/enterprise/init/GetInitFirstDefaultRole')
export const InitFirstData = params => post('/enterprise/init/InitFirstData', params)
// 删除短信配置
export const DeleteSmsSettings = id => post('/system/sms/DeleteSmsSettings', id)
// 更新或添加短信配置
export const saveOrUpdateSms = params => {
  const submitUrl = params.id ? '/system/sms/UpdateSmsSettings' : '/system/sms/CreateSmsSettings'
  return post(submitUrl, params)
}

// 查询当前企业邮箱设置
export const GetEmailSettings = () => get('/system/email/GetEmailSettings')
// 删除邮箱配置
export const DeleteEmailSettings = id => post('/system/email/DeleteEmailSettings', id)
export const SysRestore = params => post('/sys/restore', params)
// 更新或添加邮箱配置
export const saveOrUpdateEmail = params => {
  const submitUrl = params.id ? '/system/email/UpdateEmailSettings' : '/system/email/CreateEmailSettings'
  return post(submitUrl, params)
}
