export default [
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/dataIntelligent/project/Project.vue'),
    meta: {
      pageTitle: '项目管理',
      breadcrumb: [{
        text: '项目列表',
        active: true,
      }],
    },
  },
  {
    path: '/plan-detail',
    name: 'plan-detail',
    component: () => import('@/views/dataIntelligent/project/PlanDetail.vue'),
    meta: {
      pageTitle: '项目管理',
      breadcrumb: [
        {
          text: '项目列表',
          to: { path: '/risk-perception' },
        },
        {
          text: '添加计划',
          active: true,
        },
      ],
    },
  },
]
