<template>
  <el-row
    type="flex"
    justify="end"
  >
    <div
      :class="{'hidden':hidden}"
      class="pagination-container"
    >
      <span class="el-pagination__total">共 {{ total }} 条</span>
      <el-pagination
        :hide-on-single-page="true"
        :background="background"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
        v-bind="$attrs"
        :page-sizes="pageSizes"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-row>
</template>
<script>
export default {
  name: 'PageQuery',
  props: {
    // 子页面数组
    // childPage: {
    //   type: Array,
    // },
    // 执行的页面查询参数
    updateState: {
      type: String,
    },
    // 页面参数
    listQuery: {
      type: Object,
    },
    // 初始化的页面参数
    initListQuery: {
      type: Object,
    },
    cacheName: {
      type: String,
    },
    total: {
      required: true,
      type: Number,
    },
    // page: {
    //   type: Number,
    //   default: 1,
    // },
    // limit: {
    //   type: Number,
    //   default: 20,
    // },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 20, 30, 50]
      },
    },
    layout: {
      type: String,
      default: ' sizes, prev, pager, next, jumper',
    },
    background: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.listQuery.page
      },
      set(val) {
        // this.$emit('update:page', val)
        this.$set(this.listQuery, 'page', val)
      },
    },
    pageSize: {
      get() {
        return this.listQuery.pageSize
      },
      set(val) {
        // this.$emit('update:limit', val)
        this.$set(this.listQuery, 'pageSize', val)
      },
    },
  },
  destroyed() {
    const toPath = this.$route.path
    const childPage = this.$store.state.pageQuery.childPageObj[this.cacheName]
    if (childPage.indexOf(toPath) != -1) {
      this.$store.commit(this.updateState, this.listQuery)
    } else {
      this.$store.commit(this.updateState, this.initListQuery)
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.listQuery.page, limit: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.listQuery.page })
    },
  },
}
</script>

<style scoped>
.pagination-container{
  display: flex;
  align-items: center;
}
</style>
