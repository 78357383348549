const assessmentListQuery = {
  SearchStr: '',
  leader: 0, // 发起人
  order: '',
  orderField: '',
  page: 1,
  pageSize: 5,
  questionnaireCode: '',
  status: '',
  assessTargetType: '',
  assessTargetId: '',
}
export default {
  state: {
    // 子页面对象
    childPageObj: {
      // 隐私政策
      listQuery1: ['/PolicyEdit'],
      // 制度流程
      listQuery2: ['/regime-save'],
      // IT资产列表
      listQuery3: ['/assestSave', '/assetDetail'],
      // 数据发现
      listQuery4: ['/dataFoundDetail', '/dataFoundSave'],
      // 数据清单
      listQuery5: ['/dataListSave'],
      // 个人数据映射
      listQuery6: ['/dataActivityFill'],
      // 快速评估
      listQuery7: ['/compliance-process'],
      // 认证评估
      listQuery8: ['/gap-answer'],
      // PIA评估
      listQuery9: ['/pia-answer', '/pia-editor'],
      // 风险感知
      listQuery10: ['/risk-save', '/risk-perceptionDetail'],
      // 风险整改
      listQuery11: ['/risk-rectifydetail'],
      // 风险历史
      listQuery12: ['/risk-histroydetail'],
      // 隐私声明
      listQuery13: ['/statement-editor'],
      // 模板管理
      listQuery14: ['/cookies-template-save'],
      // 共享数据管理
      listQuery15: ['/dataShareSave'],
      // 合作协议管理
      listQuery16: ['/cooperation-save'],
      // 个人共享管理
      listQuery17: ['/shareAssementFill'],
      // 第三方合规管理
      listQuery18: ['/thirdAssementEditor'],
      // 用户管理
      listQuery19: ['/user-edit'],
      // 业务管理
      listQuery20: ['/product-save', 'product-detail'],
      // 业务场景
      listQuery21: ['/product-save'],
      // 项目管理
      listQuery22: ['/plan-detail'],
    },
    // 隐私政策
    listQuery1: {
      page: 1,
      pageSize: 5,
      SearchStr: '',
      issueNo: '',
      sort: '',
      orderBy: {
        issueNo: '',
      },
    },
    // 制度流程
    listQuery2: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      enterpriseId: 100,
      labelIds: [],
      libraryId: 0,
    },
    // IT资产列表
    listQuery3: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      assetsType: '',
      productId: '',
      responsiblePerson: '',
      geographicLocation: '',
      orderBy: {
        responsiblePerson: '',
      },
    },
    // 数据发现
    listQuery4: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      assetsType: '',
      responsiblePerson: '',
    },
    // 数据清单
    listQuery5: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      assetsId: 0,
      saveDatabase: '',
      saveTable: '',
      orderBy: {
        assetsName: '',
        saveDatabase: '',
        saveTable: '',
      },
    },
    // 个人数据映射
    listQuery6: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      status: '',
      userId: '',
    },
    // 快速评估
    listQuery7: { ...assessmentListQuery },
    // 认证评估
    listQuery8: { ...assessmentListQuery },
    // PIA评估
    listQuery9: { ...assessmentListQuery },
    // 风险感知
    listQuery10: {
      SearchStr: '',
      order: '',
      orderField: '',
      page: 1,
      pageSize: 10,
      riskLevel: 0,
      riskObject: '',
      riskSource: '',
      riskStatus: 0,
      riskType: '',
    },
    // 风险整改
    listQuery11: {
      SearchStr: '',
      order: '',
      orderField: '',
      page: 1,
      pageSize: 10,
      riskLevel: 0,
      riskObject: '',
      riskSource: '',
      riskStatus: 0,
      riskType: '',
    },
    // 风险历史
    listQuery12: {
      SearchStr: '',
      order: '',
      orderField: '',
      page: 1,
      pageSize: 10,
      riskLevel: 0,
      riskObject: '',
      riskSource: '',
      riskStatus: 0,
      riskType: '',
    },
    // 隐私声明
    listQuery13: {
      page: 1,
      pageSize: 5,
      SearchStr: '',
      language: '',
      status: '',
      area: '',
      productId: 0,
      orderBy: {
        area: '',
        language: '',
        productId: '',
        status: '',
      },
    },
    // 模板管理
    listQuery14: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
    },
    // 共享数据管理
    listQuery15: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      shareMethod: '',
      sourceType: 0,
      order: '',
      orderField: '',
    },
    // 合作协议管理
    listQuery16: {
      page: 1,
      pageSize: 5,
      SearchStr: '',
      templateCode: '',
      thirdPartyId: 0,
      orderBy: {
        createTime: '',
        source: '',
        thirdParty: '',
      },
    },
    // 个人共享管理
    listQuery17: { ...assessmentListQuery },
    // 第三方合规管理
    listQuery18: { ...assessmentListQuery },
    // 用户管理
    listQuery19: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      orderBy: {
        dept: '',
        role: '',
      },
    },
    // 业务管理
    listQuery20: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      status: '',
      technicalDirectorId: '',
      productManagerId: '',
      productType: '',
    },
    // 业务场景
    listQuery21: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      OrderField: '',
      order: '',
    },
    // 项目管理
    listQuery22: {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      OrderField: '',
      order: '',
    },
  },
  getters: {},
  mutations: {
    UPDATE_LISTQUERY1(state, val) {
      state.listQuery1 = val
    },
    UPDATE_LISTQUERY2(state, val) {
      state.listQuery2 = val
    },
    UPDATE_LISTQUERY3(state, val) {
      state.listQuery3 = val
    },
    UPDATE_LISTQUERY4(state, val) {
      state.listQuery4 = val
    },
    UPDATE_LISTQUERY5(state, val) {
      state.listQuery5 = val
    },
    UPDATE_LISTQUERY6(state, val) {
      state.listQuery6 = val
    },
    UPDATE_LISTQUERY7(state, val) {
      state.listQuery7 = val
    },
    UPDATE_LISTQUERY8(state, val) {
      state.listQuery8 = val
    },
    UPDATE_LISTQUERY9(state, val) {
      state.listQuery9 = val
    },
    UPDATE_LISTQUERY10(state, val) {
      state.listQuery10 = val
    },
    UPDATE_LISTQUERY11(state, val) {
      state.listQuery11 = val
    },
    UPDATE_LISTQUERY12(state, val) {
      state.listQuery12 = val
    },
    UPDATE_LISTQUERY13(state, val) {
      state.listQuery13 = val
    },
    UPDATE_LISTQUERY14(state, val) {
      state.listQuery14 = val
    },
    UPDATE_LISTQUERY15(state, val) {
      state.listQuery15 = val
    },
    UPDATE_LISTQUERY16(state, val) {
      state.listQuery16 = val
    },
    UPDATE_LISTQUERY17(state, val) {
      state.listQuery17 = val
    },
    UPDATE_LISTQUERY18(state, val) {
      state.listQuery18 = val
    },
    UPDATE_LISTQUERY19(state, val) {
      state.listQuery19 = val
    },
    UPDATE_LISTQUERY20(state, val) {
      state.listQuery20 = val
    },
    UPDATE_LISTQUERY21(state, val) {
      state.listQuery21 = val
    },
    UPDATE_LISTQUERY22(state, val) {
      state.listQuery22 = val
    },
  },
  actions: {},
}
