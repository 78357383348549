export default [
  {
    path: '/statement',
    name: 'statement',
    component: () => import('@/views/statement/index.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-version',
    name: 'statement-version',
    component: () => import('@/views/statement/version.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          to: 'statement',
        },
        {
          text: '声明版本管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-template',
    name: 'statement-template',
    component: () => import('@/views/statement/version-template.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          to: 'statement',
        },
        {
          text: '声明版本管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-version-edit',
    name: 'statement-version-edit',
    component: () => import('@/views/statement/version-edit.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          to: 'statement',
        },
        {
          text: '声明版本管理',
          to: 'statement-version',
        },
        {
          text: '声明编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-save',
    name: 'statement-save',
    component: () => import('@/views/statement/StatementSave.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          to: 'statement',
        },
        {
          text: '声明编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-examine',
    name: 'statement-examine',
    component: () => import('@/views/statement/version-examine.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          to: 'statement',
        },
        {
          text: '声明版本管理',
          to: 'statement-version',
        },
        {
          text: '声明审核',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-editor',
    name: 'statement-editor',
    component: () => import('@/views/statement/StatementEdit.vue'),
    meta: {
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明管理',
          to: 'statement',
        },
        {
          text: '声明编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statement-release',
    name: 'statement-release',
    component: () => import('@/views/statement/StatementPulishList.vue'),
    meta: {
      action: 'manage',
      pageTitle: '隐私声明管理',
      breadcrumb: [
        {
          text: '声明发布',
          active: true,
        },
      ],
    },
  },

]
