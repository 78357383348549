import Vue from 'vue'
const phoneAreaCodeOptions = [
  { label: '中国大陆 +86', value: '+86'},
  { label: '中国香港 +852', value: '+852'},
  { label: '中国澳门 +853', value: '+853'},
  { label: '中国台湾 +886', value: '+886'},
  { label: '韩国 +82', value: '+82'},
  { label: '日本 +81', value: '+81'},
  { label: '美国 +1', value: '+1'},
  { label: '加拿大 +1', value: '+1'},
  { label: '英国 +44', value: '+44'},
  { label: '新加坡 +65', value: '+65'},
  { label: '马来西亚 +60', value: '+60'},
  { label: '泰国 +66', value: '+66'},
  { label: '越南 +84', value: '+84'},
  { label: '菲律宾 +63', value: '+63'},
  { label: '印度尼西亚 +62', value: '+62'},
  { label: '意大利 +39', value: '+39'},
  { label: '俄罗斯 +7', value: '+7'},
  { label: '新西兰 +64', value: '+64'},
  { label: '荷兰 +31', value: '+31'},
  { label: '瑞典 +46', value: '+46'},
  { label: '澳大利亚 +61', value: '+61'},
  { label: '乌克兰 +380', value: '+380'},
  { label: '法国 +33', value: '+33'},
  { label: '德国 +49', value: '+49'},
  { label: '阿富汗 +93', value: '+93'},
  { label: '阿尔巴尼亚 +355', value: '+355'},
  { label: '阿尔及利亚 +213', value: '+213'},
  { label: '东萨摩亚(美) +1684', value: '+1684'},
  { label: '安道尔 +376', value: '+376'},
  { label: '安哥拉 +244', value: '+244'},
  { label: '安圭拉岛(英) +1264', value: '+1264'},
  { label: '安提瓜和巴布达 +1268', value: '+1268'},
  { label: '阿根廷 +54', value: '+54'},
  { label: '亚美尼亚 +374', value: '+374'},
  { label: '阿鲁巴岛 +297', value: '+297'},
  { label: '奥地利 +43', value: '+43'},
  { label: '阿塞拜疆 +994', value: '+994'},
  { label: '巴林 +973', value: '+973'},
  { label: '孟加拉国 +880', value: '+880'},
  { label: '巴巴多斯 +1246', value: '+1246'},
  { label: '白俄罗斯 +375', value: '+375'},
  { label: '比利时 +32', value: '+32'},
  { label: '伯利兹 +501', value: '+501'},
  { label: '贝宁 +229', value: '+229'},
  { label: '百慕大群岛(英) +1441', value: '+1441'},
  { label: '不丹 +975', value: '+975'},
  { label: '玻利维亚 +591', value: '+591'},
  { label: '波斯尼亚和黑塞哥维那 +387', value: '+387'},
  { label: '博茨瓦纳 +267', value: '+267'},
  { label: '巴西 +55', value: '+55'},
  { label: '保加利亚 +359', value: '+359'},
  { label: '布基纳法索 +226', value: '+226'},
  { label: '布隆迪 +257', value: '+257'},
  { label: '喀麦隆 +237', value: '+237'},
  { label: '佛得角 +238', value: '+238'},
  { label: '开曼群岛(英) +1345', value: '+1345'},
  { label: '中非 +236', value: '+236'},
  { label: '乍得 +235', value: '+235'},
  { label: '智利 +56', value: '+56'},
  { label: '圣诞岛 +61', value: '+61'},
  { label: '科科斯岛 +61', value: '+61'},
  { label: '哥伦比亚 +57', value: '+57'},
  { label: '科摩罗 +269', value: '+269'},
  { label: '刚果 +242', value: '+242'},
  { label: '科克群岛(新) +682', value: '+682'},
  { label: '哥斯达黎加 +506', value: '+506'},
  { label: '克罗地亚 +385', value: '+385'},
  { label: '古巴 +53', value: '+53'},
  { label: '塞浦路斯 +357', value: '+357'},
  { label: '捷克 +420', value: '+420'},
  { label: '丹麦 +45', value: '+45'},
  { label: '吉布提 +253', value: '+253'},
  { label: '多米尼克国 +1767', value: '+1767'},
  { label: '多米尼加共和国 +1809', value: '+1809'},
  { label: '厄瓜多尔 +593', value: '+593'},
  { label: '埃及 +20', value: '+20'},
  { label: '萨尔瓦多 +503', value: '+503'},
  { label: '赤道几内亚 +240', value: '+240'},
  { label: '厄立特里亚 +291', value: '+291'},
  { label: '爱沙尼亚 +372', value: '+372'},
  { label: '埃塞俄比亚 +251', value: '+251'},
  { label: '福克兰群岛 +500', value: '+500'},
  { label: '法罗群岛(丹) +298', value: '+298'},
  { label: '斐济 +679', value: '+679'},
  { label: '芬兰 +358', value: '+358'},
  { label: '法属波里尼西亚 +689', value: '+689'},
  { label: '加蓬 +241', value: '+241'},
  { label: '冈比亚 +220', value: '+220'},
  { label: '格鲁吉亚 +995', value: '+995'},
  { label: '加纳 +233', value: '+233'},
  { label: '直布罗陀(英) +350', value: '+350'},
  { label: '希腊 +30', value: '+30'},
  { label: '格陵兰岛 +299', value: '+299'},
  { label: '格林纳达 +1473', value: '+1473'},
  { label: '瓜德罗普岛(法) +590', value: '+590'},
  { label: '关岛(美) +1671', value: '+1671'},
  { label: '危地马拉 +502', value: '+502'},
  { label: '几内亚 +224', value: '+224'},
  { label: '几内亚比绍 +245', value: '+245'},
  { label: '圭亚那 +592', value: '+592'},
  { label: '海地 +509', value: '+509'},
  { label: '洪都拉斯 +504', value: '+504'},
  { label: '匈牙利 +36', value: '+36'},
  { label: '冰岛 +354', value: '+354'},
  { label: '印度 +91', value: '+91'},
  { label: '伊郎 +98', value: '+98'},
  { label: '伊拉克 +964', value: '+964'},
  { label: '爱尔兰 +353', value: '+353'},
  { label: '以色列 +972', value: '+972'},
  { label: '科特迪瓦 +225', value: '+225'},
  { label: '牙买加 +1876', value: '+1876'},
  { label: '约旦 +962', value: '+962'},
  { label: '柬埔塞 +855', value: '+855'},
  { label: '哈萨克斯坦 +7', value: '+7'},
  { label: '肯尼亚 +254', value: '+254'},
  { label: '基里巴斯 +686', value: '+686'},
  { label: '科威特 +965', value: '+965'},
  { label: '吉尔吉斯斯坦 +996', value: '+996'},
  { label: '老挝 +856', value: '+856'},
  { label: '拉脱维亚 +371', value: '+371'},
  { label: '黎巴嫩 +961', value: '+961'},
  { label: '莱索托 +266', value: '+266'},
  { label: '利比里亚 +231', value: '+231'},
  { label: '利比亚 +218', value: '+218'},
  { label: '列支敦士登 +423', value: '+423'},
  { label: '立陶宛 +370', value: '+370'},
  { label: '卢森堡 +352', value: '+352'},
  { label: '马其顿 +389', value: '+389'},
  { label: '马达加斯加 +261', value: '+361'},
  { label: '马拉维 +265', value: '+265'},
  { label: '马尔代夫 +960', value: '+960'},
  { label: '马里 +223', value: '+223'},
  { label: '马耳他 +356', value: '+356'},
  { label: '马绍尔群岛 +692', value: '+692'},
  { label: '马提尼克(法) +596', value: '+596'},
  { label: '毛里塔尼亚 +222', value: '+222'},
  { label: '毛里求斯 +230', value: '+230'},
  { label: '马约特岛 +262', value: '+262'},
  { label: '墨西哥 +52', value: '+52'},
  { label: '密克罗尼西亚(美) +691', value: '+691'},
  { label: '摩纳哥 +377', value: '+337'},
  { label: '蒙古 +976', value: '+976'},
  { label: '蒙特塞拉特岛(英) +1664', value: '+1664'},
  { label: '摩洛哥 +212', value: '+212'},
  { label: '莫桑比克 +258', value: '+258'},
  { label: '缅甸 +95', value: '+95'},
  { label: '纳米比亚 +264', value: '+264'},
  { label: '瑙鲁 +674', value: '+674'},
  { label: '尼泊尔 +977', value: '+977'},
  { label: '荷属安的列斯群岛 +599', value: '+599'},
  { label: '新喀里多尼亚群岛(法) +687', value: '+687'},
  { label: '尼加拉瓜 +505', value: '+505'},
  { label: '尼日尔 +227', value: '+227'},
  { label: '尼日利亚 +234', value: '+234'},
  { label: '纽埃岛(新) +683', value: '+683'},
  { label: '诺福克岛(澳) +672', value: '+672'},
  { label: '朝鲜 +850', value: '+850'},
  { label: '马里亚纳群岛 +1670', value: '+1670'},
  { label: '挪威 +47', value: '+47'},
  { label: '阿曼 +968', value: '+968'},
  { label: '巴基斯坦 +92', value: '+92'},
  { label: '帕劳(美) +680', value: '+680'},
  { label: '巴拿马 +507', value: '+507'},
  { label: '巴布亚新几内亚 +675', value: '+675'},
  { label: '巴拉圭 +595', value: '+595'},
  { label: '秘鲁 +51', value: '+51'},
  { label: '波兰 +48', value: '+48'},
  { label: '葡萄牙 +351', value: '+351'},
  { label: '波多黎各(美) +1', value: '+1'},
  { label: '卡塔尔 +974', value: '+974'},
  { label: '摩尔多瓦 +373', value: '+373'},
  { label: '留尼汪岛 +262', value: '+262'},
  { label: '罗马尼亚 +40', value: '+40'},
  { label: '卢旺达 +250', value: '+250'},
  { label: '阿森松(英) +247', value: '+247'},
  { label: '圣赫勒拿 +290', value: '+290'},
  { label: '圣克里斯托弗和尼维斯 +1869', value: '+1689'},
  { label: '圣卢西亚 +1758', value: '+1758'},
  { label: '圣皮埃尔岛及密克隆岛 +508', value: '+508'},
  { label: '圣文森特岛(英) +1784', value: '+1784'},
  { label: '西萨摩亚 +685', value: '+685'},
  { label: '圣马力诺 +378', value: '+378'},
  { label: '圣多美和普林西比 +239', value: '+239'},
  { label: '沙特阿拉伯 +966', value: '+966'},
  { label: '塞内加尔 +221', value: '+221'},
  { label: '塞舌尔 +248', value: '+248'},
  { label: '塞拉利昂 +232', value: '+232'},
  { label: '斯洛伐克 +421', value: '+421'},
  { label: '斯洛文尼亚 +386', value: '+386'},
  { label: '所罗门群岛 +677', value: '+677'},
  { label: '索马里 +252', value: '+252'},
  { label: '南非 +27', value: '+27'},
  { label: '西班牙 +34', value: '+34'},
  { label: '斯里兰卡 +94', value: '+94'},
  { label: '苏丹 +249', value: '+249'},
  { label: '苏里南 +597', value: '+597'},
  { label: '斯威士兰 +268', value: '+268'},
  { label: '瑞士 +41', value: '+41'},
  { label: '叙利亚 +963', value: '+963'},
  { label: '塔吉克斯坦 +992', value: '+992'},
  { label: '巴哈马国 +1242', value: '+1242'},
  { label: '梵蒂冈 +14397', value: '+14937'},
  { label: '多哥 +228', value: '+228'},
  { label: '汤加 +676', value: '+676'},
  { label: '特立尼达和多巴哥 +1868', value: '+1868'},
  { label: '突尼斯 +216', value: '+216'},
  { label: '土耳其 +90', value: '+90'},
  { label: '土库曼斯坦 +993', value: '+993'},
  { label: '特克斯和凯科斯群岛(英) +1649', value: '+1649'},
  { label: '图瓦卢 +688', value: '+688'},
  { label: '乌干达 +256', value: '+256'},
  { label: '英国 +44', value: '+44'},
  { label: '坦桑尼亚 +255', value: '+255'},
  { label: '乌拉圭 +598', value: '+598'},
  { label: '乌兹别克斯坦 +998', value: '+998'},
  { label: '瓦努阿图 +678', value: '+678'},
  { label: '委内瑞拉 +58', value: '+58'},
  { label: '维尔京群岛(英) +1340', value: '+1340'},
  { label: '也门 +967', value: '+967'},
  { label: '南斯拉夫 +381', value: '+381'},
  { label: '赞比亚 +260', value: '+260'},
  { label: '桑给巴尔 +259', value: '+259'},
  { label: '津巴布韦 +263', value: '+263'},
]
Vue.prototype.phoneAreaCodeOptions = phoneAreaCodeOptions