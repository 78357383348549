import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router/index'
// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle,no-unused-vars
import { error } from '@core/utils/utils'
import { Loading } from 'element-ui'

const axiosIns = axios.create({
  // baseURL: baseUrlConfig.baseUrl,
  baseURL: window.g.baseUrl,
  withCredentials: false,
  timeout: 60000, // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns
// eslint-disable-next-line consistent-return

axiosIns.interceptors.response.use(response => {
  const res = response.data
  if (res.code === 401) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    // Remove userData from localStorage
    localStorage.removeItem('userData')
    router.replace({ name: 'login' })
    router.push({ path: '/login' })
  } if (res.code === 402) {
    router.replace({ name: 'not-authorized' })
    router.push({ path: '/not-authorized' })
  } else {
    return response
  }
// eslint-disable-next-line no-shadow
}, error => Promise.reject(error))

export default axiosIns

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axiosIns({
      url,
      method: 'get',
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export function post(url, params, config) {
  return new Promise((resolve, reject) => {
    axiosIns.post(url, params, config)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    axiosIns({
      url,
      method: 'delete',
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axiosIns.put(url, params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getByBlob(url, params) {
  return new Promise((resolve, reject) => {
    axiosIns({
      url,
      method: 'get',
      params,
      responseType: 'blob',
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// get 下载文件
export function downloadByGet(url, params = {}) {
  const loadingInstance = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  const newUrl = url.indexOf('?') === -1 ? `${url}?t=${new Date().getTime()}` : `${url}&t=${new Date().getTime()}`
  return axiosIns({
    url: newUrl,
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 0,
  }).then(data => {
    if (data) {
      const str = typeof data.headers['content-disposition'] === 'undefined' ? '' : data.headers['content-disposition'].split(';')[1].replace('*', '').replace('utf-8\'\'', '')
      const filename = typeof str.replaceAll(' ', '').split('filename=')[1] === 'undefined' ? '' : str.split('filename=')[1].replaceAll('"', '')
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = window.URL.createObjectURL(data.data)
      // link.download = fileName
      link.setAttribute('download', decodeURIComponent(filename))
      // link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(link.href)
      loadingInstance.close()
    } else {
      loadingInstance.close()
      error('下载失败')
    }
  }).catch(() => {
    error('下载失败')
    loadingInstance.close()
  })
}
