import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin, VBToggle,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import loading from '@core/components/loading/index'
import Moment from 'moment'
import confrim from '@core/utils/confrim'
import { formatDateStr, navActiveInit, hasPermission } from '@core/utils/utils'
import '@/assets/scss/el-reset.css'
// Global Components
import './global-components'
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/map'
import '@/libs/phoneAreaCode'
// Axios Mock Adapter
// import '@/@fake-db/db'
import ZmTreeOrg from 'zm-tree-org'
import 'zm-tree-org/lib/zm-tree-org.css'
// 输入框指令
import inputFilter from '@/directives/input-filter/index.js'
import VueCropper from 'vue-cropper'
import jsPlumb from 'jsplumb'
import App from './App.vue'
import store from './store'
import router from './router'

ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.Tooltip.props.openDelay.default = 1000
Vue.prototype.$hasPermission = hasPermission

Vue.use(ZmTreeOrg)
// BSV Plugin Registration
Vue.use(ElementUI, { size: 'small' })
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VBToggle)
Vue.use(loading)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// feather font icons
require('@core/assets/fonts/feather/iconfont.css')

Vue.filter('formatDate', value => Moment(value)
  .format('YYYY-MM-DD'))

Vue.config.productionTip = false

Vue.prototype.formatDateStr = formatDateStr
Vue.prototype.navActiveInit = navActiveInit
Vue.prototype.pageH = document.body.clientHeight
inputFilter(Vue)
// Vue.prototype.inputFilter = inputFilter
// inputFilter(Vue)
// 富文本编辑器toolbar
const toolbarConfig = {
  toolbarKeys: ['bold', 'underline', 'italic', 'through', 'code', 'sub', 'sup', 'clearStyle', 'color', 'bgColor', 'fontSize', 'fontFamily', 'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'lineHeight', // "insertImage",
    // "deleteImage",
    // "editImage",
    // "viewImageLink",
    // "imageWidth30",
    // "imageWidth50",
    // "imageWidth100",
    'divider', 'emotion', 'insertLink', 'editLink', 'unLink', 'viewLink', 'codeBlock', 'blockquote', 'headerSelect', 'header1', 'header2', 'header3', 'header4', 'header5', 'todo', 'redo', 'undo', // "fullScreen",
    'bulletedList', 'numberedList', 'insertTable', 'deleteTable', 'insertTableRow', 'deleteTableRow', 'insertTableCol', 'deleteTableCol', 'tableHeader', 'tableFullWidth', // "insertVideo",
    // "uploadVideo",
    // "uploadImage",
    // "codeSelectLang"
  ],
}
Vue.prototype.toolbarConfig = toolbarConfig
Vue.prototype.autosize = { minRows: 3 }
Vue.use(VueCropper)
const afterTodayPickerOptions = {
  disabledDate(time) {
    return time.getTime() < Date.now() - 8.64e7
  },
}
Vue.prototype.afterTodayPickerOptions = afterTodayPickerOptions
Vue.prototype.$jsPlumb = jsPlumb.jsPlumb
function stopError() {
  return true
}
window.onerror = stopError
new Vue({
  router,
  store,
  render: h => h(App),
  errorCaptured(e) {
    return null
  },
}).$mount('#app')
